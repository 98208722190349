import * as types from '../mutation-types'

/**
 * Initial state
 */
const state = {
  apiUrl: window.localStorage.getItem('apiUrl'),
}

/**
 * Mutations
 */
const mutations = {
  [types.SET_API_URL](state, apiUrl ) {
    state.apiUrl = apiUrl
    window.localStorage.setItem('apiUrl', apiUrl)
  }
}

/**
 * Actions
 */
const actions = {
  setApiUrl({ commit }, apiUrl) {
    commit(types.SET_API_URL, apiUrl)
  },
}

/**
 * Getters
 */
const getters = {
  apiUrl: state => state.apiUrl,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
