<template>
  <div id="topPage">
    <div class="tertiary pt-4 pt-sm-6 pt-lg-10">
      <v-container class="pb-10">
        <v-row>
          <v-col cols="12" lg="10" offset-lg="1" class="pb-0">
            <h1 class="font-title-2 sm__font-title-2 primary--text mb-3">
              {{ chapter.module }}
            </h1>
            <div class="text-h6 text-body-medium secondary--text pl-0">
              {{ chapter.title }}
            </div>
            <v-row class="align-end flex-wrap mt-sm-n2">
              <v-col cols="12" sm="5" class="py-0">
                <div class="d-flex align-center justify-space-between justify-sm-start mt-4">
                  <v-btn
                    :to="{ name: 'course', params: { courseId: course.id, can_access: this.course.can_access } }"
                    dark
                    small
                    outlined
                    color="secondary"
                  >
                    <v-icon left > mdi-arrow-left</v-icon>
                    {{ $t('general.back') }}
                  </v-btn>
                  <div class="ml-sm-8">
                    <v-btn
                      :to="{ name: 'chapter', params: { chapterId: previous, can_access: this.course.can_access } }"
                      :style=" !previous ? 'visibility:hidden' : 'visibility:visible'"
                      fab
                      dark
                      depressed
                      x-small
                      color="secondary"
                      class="mr-6 mr-sm-2"
                    >
                      <v-icon dark> mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      :to="{ name: 'chapter', params: { chapterId: next, can_access: this.course.can_access } }"
                      :style=" !next ? 'visibility:hidden' : 'visibility:visible'"
                      fab
                      dark
                      depressed
                      x-small
                      color="secondary"
                    >
                      <v-icon dark> mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="7" class="py-0">
                <chapter-step
                  :chaptersCount="module.chapters_count"
                  :chaptersDone="module.chapters_done"
                  :chapters="siblings"
                  :chapterId="chapterId"
                ></chapter-step>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" lg="10" offset-lg="1">
          <template v-for="(content, index) in contents">
            <content-type
              :key="'content_' + index"
              :content="content"
            ></content-type>
          </template>
          <template v-for="(chapter, index) in chapters">
            <chapter-child
              :key="'child_' + index"
              :chapter="chapter"
            ></chapter-child>
          </template>
          <div class="text-right mb-4">
            <v-btn
              fab
              small
              depressed
              color="secondary"
              :title="$t('general.top')"
              @click="$vuetify.goTo('#topPage', { offset: 50 })"
            >
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <div class="text-center d-flex flex-wrap justify-space-between align-center mt-4">
            <div class="my-2">
              <v-btn
                color="success"
                :outlined="!chapter.done ? true : false"
                large
                @click="done()"
              >
                {{ $t('chapter.end') }}
              </v-btn>
            </div>
            <div class="my-2">
              <v-btn
                :to="{ name: 'chapter', params: { chapterId: previous, can_access: this.course.can_access } }"
                exact
                fab
                small
                depressed
                color="grey lighten-4"
                v-if="previous"
                class="mr-auto"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                :to="{ name: 'chapter', params: { chapterId: next, can_access: this.course.can_access }}"
                exact
                fab
                small
                depressed
                color="grey lighten-4"
                v-if="next"
                class="ml-2"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <div id="free-mode-container" class="d-none">
        <free-mode-end
            :description="$t('free_mode.description', {course_title: course.title})"
            :btnLabel="$t('free_mode.incentive', {price: course.price})"
            :courseId="course.id"
            v-if="!this.course.can_access"
          />
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

import ContentType from "@/components/content/Type";
import ChapterChild from "@/components/chapter/Child";
import ChapterStep from "@/components/chapter/Step.vue";
import FreeModeEnd from "@/components/FreeModeEnd";

export default {
  data() {
    return {
      chapter: false,
      chapters: false,
      contents: false,
      previous: false,
      next: false,
      module: false,
      siblings: false,
      course: false,
    };
  },
  components: {
    ContentType,
    ChapterChild,
    ChapterStep,
    FreeModeEnd
  },
  computed: {
    chapterId() {
      return this.$route.params.chapterId;
    },
    can_access() {
      return this.$route.params.can_access
    },
  },
  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        this.getChapter();
      }
    },
  },
  mounted() {
    this.getChapter();
    this.getSiblings()
  },
  methods: {
    done() {
      axios
        .get(this.$root.$options.api.url + '/chapters/' + this.chapterId + '/done')
        .then((response) => {
          this.chapter.done = response.data.done;
          this.getSiblings();

          if (this.chapter.done) {
            if (this.next) {
              this.$router.push({
                name: 'chapter',
                params: {
                  chapterId: this.next,
                  can_access: this.can_access,
                },
              });
            } else {
              this.$router.push({
                name: 'course',
                params: {
                  courseId: this.course.id,
                  can_access: this.can_access,
                },
              });
            }
          }
        });
    },
    getChapter() {
      axios
        .get(this.$root.$options.api.url + '/chapters/' + this.chapterId)
        .then((response) => {
          this.chapter = response.data.chapter;
          this.contents = this.chapter.contents;
          this.chapters = response.data.chapters;
          this.next = response.data.next;
          this.previous = response.data.previous;
          this.module = response.data.module;
          this.course = response.data.course;
          document.querySelector('#free-mode-container').classList.remove('d-none');
        });
    },
    getSiblings() {
      axios
        .get(
          this.$root.$options.api.url + '/chapters/siblings/' + this.chapterId
        )
        .then((response) => {
          this.siblings = response.data.siblings;
        });
    },
  },
};
</script>
