<template>
  <div class="fill-height tertiary">
    <div >
      <v-container class="tabs-border-bottom">
        <v-row>
          <v-col cols="12" lg="10" offset-lg="1" class="pb-0">
            <v-card flat tile class="transparent mt-6 mt-lg-8">
              <v-btn
                :to="{
                  name: 'course-evaluations',
                  params: { courseId: courseId },
                }"
                outlined
                small
                color="secondary"
                class="mb-6 mb-lg-10"
              >
                <v-icon left> mdi-arrow-left</v-icon>
                {{ $t('study_notes.evaluations.tests') }}
              </v-btn>
              <div class="d-flex flex-wrap justify-space-between align-center">
                <h2 class="flex-grow-1 font-title-2 sm__font-title-2 primary--text my-2">
                  {{ review.name }}
                </h2>
              </div>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="py-0">
      <v-row>
        <v-col lg="8" offset-lg="2">
          <p class="text-overline text-center mt-6">{{ $t('study_notes.evaluations.questions') }}</p>
          <v-pagination
            v-model="page"
            :length="questions.length"
            class="mb-8"
            :total-visible="10"
            circle
          ></v-pagination>
          <question
            class="mb-2"
            :question="currentQuestion"
            :showResult="isValidated"
            @set-review="setReview"
          ></question>
          <div v-if="evaluation.exam" class="mb-6"></div>
          <v-dialog v-model="dialog" width="auto" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="isComplete"
                v-on="on"
                v-bind="attrs"
                depressed
                large
                color="secondary"
                class="mb-4"
              >
                {{ $t('study_notes.evaluations.results') }}
              </v-btn>
            </template>
            <result
              :goodAnswers="goodAnswers"
              :badAnswers="badAnswers"
              :goodQuestions="goodQuestions"
              :badQuestions="badQuestions"
              :maxErrors="maxErrors"
              :success="success"
              :questions="questions"
              class="mt-10 mb-10"
              @showQuestion="showQuestion"
              @close-dialog="closeDialogBtn"
            />
          </v-dialog>
          <v-btn
            v-if="!isComplete"
            color="secondary"
            class="mb-12"
            elevation="8"
            x-large
            block
            @click="validateQuestion"
          >
            {{ validationBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid lightgrey;
}
</style>

<script>
import axios from "axios";
import Question from "@/components/question/Question";
import Result from "@/components/review/EvaluationResult";

export default {
  data() {
    return {
      review: false,
      evaluation: false,
      questions: false,
      current: false,
      page: 1,
      badAnswers: 0,
      goodAnswers: 0,
      badQuestions: 0,
      goodQuestions: 0,
      isComplete: false,
      success: false,
      showResult: false,
      isValidated: false,
      validationBtn: "Valider ma réponse",
      maxErrors: 0,
      dialog: false,
      minutes: 0,
      seconds: 0,
    };
  },
  components: {
    Question,
    Result,
  },
  watch: {
    page: function () {
      let current = this.getCurrentQuestion();
      if (current.validated) {
        this.isValidated = true;
      } else {
        this.isValidated = false;
      }
    },
  },
  computed: {
    currentQuestion() {
      if (this.questions) {
        return this.questions.find((question, index) => {
          return index == this.page - 1;
        });
      }
      return false;
    },
    reviewId() {
      return this.$route.params.reviewId;
    },
    courseId() {
      return this.$route.params.courseId;
    },
  },
  mounted() {
    this.getQuestions();
    this.getReview();
  },
  methods: {
    getQuestions() {
      axios
        .get(this.$root.$options.api.url + "/questions/review/" + this.reviewId)
        .then(response => {
          this.questions = response.data.questions
        })
    },
    getReview() {
      axios
        .get(this.$root.$options.api.url + "/reviews/" + this.reviewId)
        .then(response => {
          this.review = response.data.review
        })
    },
    validateQuestion() {
      if (this.isValidated) {
        this.nextQuestion();
        this.validationBtn = "Valider ma réponse";
      } else {
        if (this.page == this.questions.length) {
          this.postResultExam();
          this.isComplete = true;
        } else {
          this.postResultQuestion();
        }
      }
    },
    getCurrentQuestion() {
      if (this.questions) {
        return this.questions.find((question, index) => {
          return index == this.page - 1;
        });
      }
      return false;
    },
    postResultQuestion() {
      axios
        .post(this.$root.$options.api.url + "/reviews/result-question", {
          questions: this.questions,
          current: this.getCurrentQuestion(),
          good_answers: this.good_answers,
          bad_answers: this.bad_answers,
          current_question: this.page,
        })
        .then((response) => {
          this.questions = response.data.questions;
          this.badAnswers = response.data.bad_answers;
          this.goodAnswers = response.data.good_answers;
          this.isComplete = response.data.is_finished;
          this.success = response.data.success;
          this.isValidated = true;
          this.showResult = true;
          this.validationBtn =
            this.page == this.questions.length
              ? "Terminer l'évaluation"
              : "Prochaine question";
        });
    },
    postResultExam() {
      axios
        .post(this.$root.$options.api.url + "/reviews/result-exam", {
          questions: this.questions,
        })
        .then((response) => {
          this.questions = response.data.questions;
          this.goodAnswers = response.data.good_answers;
          this.badAnswers = response.data.bad_answers;
          this.goodQuestions = response.data.good_questions;
          this.badQuestions = response.data.bad_questions;
          this.isComplete = response.data.is_finished;
          this.success = response.data.success;
          this.maxErrors = response.data.max_errors;
          this.isValidated = true;
          this.showResult = true;
        });
    },
    nextQuestion() {
      if (this.page < this.questions.length) {
        this.page++;
      }
    },
    showQuestion(index) {
      this.page = index + 1;
    },
    closeDialogBtn(payload) {
      this.dialog = payload.dialog;
    },
    setReview(questionPinned) {
      axios
        .post(this.$root.$options.api.url + "/questions/tag-question", {
          uuid: questionPinned.uuid,
          pinned: questionPinned.isPinned,
          course_id: this.courseId,
        });
    }
  },
};
</script>
