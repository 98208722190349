import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Register from '@/views/Register'
import ForgotPassword from '@/views/ForgotPassword'
import ResetPassword from '@/views/ResetPassword'
import Home from '@/views/pages/Home'
import Module from '@/views/modules/Module'
import User from '@/views/users/User'
import CGV from '@/views/pages/CGV.vue';

import Chapter from '@/views/chapters/Chapter'
import ChapterTraining from '@/views/lessons/ChapterTraining.vue';
import ChapterQuiz from '@/views/lessons/ChapterQuiz.vue';
import ChapterTags from '@/views/chapters/Tags.vue';

import Courses from '@/views/courses/Courses'
import Course from '@/views/courses/Course'
import CourseEvaluations from '@/views/courses/Evaluations'

import Evaluation from '@/views/evaluations/Evaluation'
import Evaluations from '@/views/evaluations/Evaluations'
import FreeEvaluation from '@/views/evaluations/FreeEvaluation'

import Reviews from '@/views/reviews/Reviews'
import Review from '@/views/reviews/Review'
import ReviewEvaluation from '@/views/reviews/ReviewEvaluation'

import PurchaseCourse from '@/views/PurchaseCourse'
import CheckoutCharge from '@/views/pages/CheckoutCharge'

Vue.use(VueRouter)

export default new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes: [{
    path: '/login',
    name: 'login',
    component: Login,
  }, {
    path: '/register',
    name: 'register',
    component: Register,
  }, {
    path: '/forgot-password',
    name: 'forgot_password',
    component: ForgotPassword,
  }, {
    path: '/password/token/:token',
    name: 'reset_password',
    component: ResetPassword,
  }, {
    path: '/',
    name: 'home',
    component: Home,
  }, {
    path: '/user',
    name: 'user',
    component: User,
  }, {
    path:'/user/:courseId',
    name: 'user-course',
    component: User,
  }, {
    path: '/modules/:moduleId',
    name: 'module',
    component: Module,
  }, {
    path: '/evaluation/:evaluationId/:courseId',
    name: 'evaluation',
    component: Evaluation,
  }, {
    path: '/evaluations/:courseId',
    name: 'evaluations',
    component: Evaluations,
  }, {
    path: '/courses',
    name: 'courses',
    component: Courses,
  }, {
    path: '/courses/:courseId',
    name: 'course',
    component: Course,
  }, {
    path: '/chapters/:chapterId',
    name: 'chapter',
    component: Chapter,
  }, {
    path: '/lessons/module/chapter-training',
    name: 'chapter-training',
    component: ChapterTraining,
    props: true
  }, {
    path: '/lessons/module/chapter-quiz',
    name: 'chapter-quiz',
    component: ChapterQuiz,
    props: true
  }, {
    path: '/courses/:courseId/evaluations',
    name: 'course-evaluations',
    component: CourseEvaluations,
  }, {  
    path: '/tags/:moduleId',
    name: 'tags',
    component: ChapterTags,
  }, {
    path: '/courses/:courseId/reviews',
    name: 'course-reviews',
    component: Reviews,
  }, {
    path: '/review/:reviewId',
    name: 'review',
    component: Review,
  }, {
    path: '/purchase-course/:courseId',
    name: 'purchase-course',
    component: PurchaseCourse,
  }, {
    path: '/checkout-charge/:orderId/:courseId',
    name: 'checkout-charge',
    component: CheckoutCharge,
  }, {
    path: '/CGV',
    name: 'cgv',
    component: CGV,
  }, {
    path: '/review-evaluation/:reviewId/:courseId',
    name: 'review-evaluation',
    component: ReviewEvaluation,
  }, {
    path: '/free/evaluation/:evaluationId/:courseId',
    name: 'free-evaluation',
    component: FreeEvaluation,
  },
]
});
