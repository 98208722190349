<template>
  <div class="tertiary fill-height">
    <v-container>
      <v-row class="py-0">
        <v-col cols="12" lg="10" offset-lg="1">
          <page-banner>
            <template v-slot:subheader>{{ $t('study_notes.sub_header') }}</template>
            <template v-slot:page-title>{{ course.title }}</template>
          </page-banner>
          <course-nav :courseId="courseId"></course-nav>
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col cols="12" sm="11" md="10" offset-md="1" lg="8" offset-lg="1">
          <h2 v-if="reviews < 1" class="text-h5">{{ $t('study_notes.empty') }}</h2>
          <review-list :reviews="reviews" :courseId="courseId" @delete-review="deleteReview"></review-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import ReviewList from "@/components/review/List";
import PageBanner from "@/components/PageBanner";
import CourseNav from "@/components/course/Nav";

export default {
  components: {
    ReviewList,
    PageBanner,
    CourseNav,
  },
  data() {
    return {
      reviews: false,
      course: false,
    };
  },
  mounted() {
    this.getReviews();
    this.getCourse();
  },
  computed: {
    courseId() {
      return this.$route.params.courseId;
    },
  },
  methods: {
    getReviews() {
      axios
        .get(this.$root.$options.api.url + "/reviews/course/" + this.courseId)
        .then((response) => {
          this.reviews = response.data.reviews;
        });
    },
    getCourse() {
      axios
        .get(this.$root.$options.api.url + "/courses/" + this.courseId)
        .then((response) => {
          this.course = response.data.course;
        });
    },
    deleteReview(id) {
      axios
        .delete(this.$root.$options.api.url + "/reviews/" + id)
        .then(() => {
          this.getReviews();
        })
    }
  },
};
</script>
