<template>
  <div>
    <div class="tertiary">
      <v-container>
        <v-row>
          <v-col cols="12" lg="8" offset-lg="2">
            <v-card flat tile class="transparent mt-8 mt-md-12 mt-lg-16 mb-2">
              <h1 class="font-title-2 sm__font-title-2 primary--text mb-0">
                {{ $t('general_conditions.title') }}
              </h1>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
<v-container>
  <v-row>
    <v-col cols="12" lg="8" offset-lg="2">
      <div v-html="$t('general_conditions.content')" />
    </v-col>
  </v-row>
</v-container>
  </div>
</template>

<style lang="scss" scoped>
  h2 {
    margin-top: 2rem;
  }
</style>

<script>
  export default {
    name: "cgv",

  }
</script>

<style lang="scss" scoped>

</style>
