<template>
  <v-col
    cols="12"
    lg="10"
    offset-lg="1"
  >
    <v-hover v-slot:default="{ hover }">
      <v-sheet
        :class="['d-lg-flex flex-wrap', { 'on-hover': hover }]"
        :elevation="can_access ? hover ? 14 : 10 : hover ? 7 : 4"
      >
        <div class="d-lg-flex teaser-wrapper">
          <div class="teaser-header teaser-header-bg secondary px-4 pt-6 pb-3 align-center">
            <div class="mr-3">
              <v-img
                  src="@/assets/sailboat.svg"
                  contain
                  class="teaser-icon"
              ></v-img>
              <v-img
                  src="@/assets/motorboat-2.svg"
                  contain
                  class="teaser-icon"
              ></v-img>
            </div>
            <div>
              <h2
                  class="flex-grow-1 sm__font-title-2 font-title-2 line-clamp-3 white--text"
              >
                {{ course.title }}
              </h2>
              <div
                  class="text-h5 primary--text"
                  v-if="(can_access || course.is_expired) && course.chapters_done_percentage > 0"
              >
                {{ $t('teaser.ongoing') }}
              </div>
              <p
                  v-if="can_access || course.is_expired"
                  class="white--text mb-0 mt-4"
              >
                {{ $t('teaser.subscription_end', { end : course.expiration_date ? course.expiration_date : $t('teaser.unlimited') }) }}
              </p>
              <span class="teaser-error teaser-badge px-2 text-uppercase" v-if="course.is_expired">{{ $t('teaser.expired') }}</span>
            </div>
          </div>
          <div class="flex-grow-1 my-auto px-4 pt-4 pt-lg-9 pb-4 pb-lg-6">
            <div v-if="can_access || course.is_expired">
              <v-progress-linear
                  color="primary lighten-5"
                  class="mb-4"
                  height="50"
                  :value="course.chapters_done_percentage"
              >
                <template v-if="course.is_expired">
                  {{ $t('teaser.expired_progress', { percentage: course.chapters_done_percentage }) }}
                </template>
              </v-progress-linear>
              <div class="d-flex flex-wrap justify-space-between" v-if="!course.is_expired">
                <div class="text-h6 text-body-medium primary--text">
                  {{ $t('teaser.progress', { percentage: course.chapters_done_percentage }) }}
                </div>
                <div class="ml-auto">
                  <v-btn text class="mr-1" @click.stop="dialog = true">{{ $t('teaser.details') }}</v-btn>
                  <v-btn
                      tile
                      depressed
                      color="secondary"
                      @click="goToCourse()"
                      v-if="can_access"
                  >{{ $t('general.continue') }}</v-btn>
                </div>
              </div>
            </div>
            <div v-if="!can_access">
              <p class="mb-6 line-clamp-2" v-if="!course.is_expired">
                {{ course.teaser }}
              </p>
              <div class="d-flex flex-wrap justify-space-between">
                <div class="text-h6 text-body-medium primary--text">
                  {{ $t('teaser.price', { price: course.gross_price === null ? $t('teaser.free') : course.price}) }}
                </div>
                <div class="ml-auto">
                  <v-btn text class="mr-1" @click.stop="dialog = true">{{ $t('teaser.details') }}</v-btn>
                  <v-btn
                      tile
                      depressed
                      color="secondary"
                      @click="goToCourse()"
                      v-if="!can_access && !course.is_expired"
                  >{{ $t('teaser.free_trial') }}</v-btn>
                  <v-btn
                      tile
                      depressed
                      color="primary"
                      @click="goToPurchase()"
                      v-if="course.is_expired"
                  >{{ $t('teaser.renew') }}</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="teaser-warning flex-grow-1 px-3 py-3 align-center"
            v-if="course.expiration_date && can_access && !course.is_expired && course.remaining_days < 31 && course.remaining_days >= 0"
        >
          {{ $t('teaser.expires_soon') }}
          <v-btn
              class="ml-4"
              tile
              depressed
              color="primary"
              @click="goToPurchase()"
          >{{ $t('teaser.renew') }}</v-btn>
        </div>
      </v-sheet>
    </v-hover>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-sheet scrollable tile>
        <v-img src="@/assets/bg-secondary.svg" style="min-height:100vh" cover>
          <v-responsive>
            <div class="tertiary pt-16 pb-4 pb-sm-6">
              <v-container class="flex-grow-1">
                <div class="d-flex flex-wrap justify-space-between align-end">
                  <div>
                    <v-btn
                      @click.stop="dialog = false"
                      color="secondary"
                      outlined
                    >
                      <v-icon left>mdi-close</v-icon>
                      {{ $t('general.close') }}
                    </v-btn>
                    <div class="paragraph-title primary--text mt-8">
                      {{ $t('teaser.course_details') }}
                    </div>
                    <h4 class="secondary--text text-h4">
                      {{ course.title }}
                    </h4>
                  </div>
                  <v-btn
                    x-large
                    depressed
                    tile
                    color="primary"
                    class="mt-8"
                    @click.stop="dialog = false"
                    @click="goToCourse()"
                    v-if="can_access"
                  >{{ $t('general.continue') }}</v-btn>
                  <v-btn
                      x-large
                      depressed
                      tile
                      color="primary"
                      class="mt-8"
                      @click.stop="dialog = false"
                      @click="goToPurchase()"
                      v-if="course.is_expired"
                  >{{ $t('teaser.course_renew') }}</v-btn>
                  <v-btn
                    x-large
                    depressed
                    tile
                    color="primary"
                    class="mt-8"
                    @click.stop="dialog = false"
                    @click="goToPurchase()"
                    v-if="!can_access && !course.is_expired"
                  >{{ $t('teaser.course_buy') }}</v-btn>
                  <v-btn
                    x-large
                    depressed
                    tile
                    color="primary"
                    class="mt-8"
                    @click.stop="dialog = false"
                    @click="goToCourse()"
                    v-if="!can_access && !course.is_expired"
                  >{{ $t('teaser.free_trial') }}</v-btn>
                </div>
              </v-container>
            </div>
            <v-container>
              <v-card-text
                class="course-description pt-16 pt-sm-32 pt-lg-48 pb-12 pb-sm-24"
                v-html="course.description"
              >
              </v-card-text>
              <v-card-actions class="px-4 pb-4"> </v-card-actions>
            </v-container>
          </v-responsive>
        </v-img>
      </v-sheet>
    </v-dialog>
  </v-col>
</template>

<script>

export default {
  props: [
    "course",
    "can_access",
  ],
  data() {
    return {
      modulesDonePercent: this.course.modules_done_percentage,
      modulesCount: this.course.modules_count,
      dialog: false,
    };
  },
  computed: {
    modulesDone() {
      return (
        (Number(this.modulesDonePercent) * Number(this.modulesCount)) / 100
      );
    },
  },
  methods: {
    goToCourse() {
      this.$router.push({
        name: 'course',
        params: {
          courseId: this.$props.course.id,
          can_access: this.$props.can_access,
        },
      });
    },
    goToPurchase() {
      this.$router.push({
        name: 'purchase-course',
        params: {
          courseId: this.$props.course.id,
        },
      });
    },
    userLoggedIn() {
      if (this.$root.$options.api.token === null) {
        return false;
      }

      if (this.$root.$options.api.token === "undefined") {
        return false;
      }

      return true;
    },
  },
}

</script>
