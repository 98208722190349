<template>
  <div>
    <template v-for="(module, index) in modules">
      <v-hover :key="index" v-slot:default="{ hover }">
        <v-sheet
          :class="['d-lg-flex mb-9', { 'on-hover': hover }]"
          :elevation="hover ? 5 : 2"
        >
          <div class="teaser-header teaser-header-bg secondary px-4 pt-6 pb-3">
            <div class="teaser-header__titles">
              <h2 class="flex-grow-1 line-clamp-2 font-title-3 white--text">
                {{ module.title }}
              </h2>
              <div class="text-h6 text-body-medium primary--text">
                {{ $t('module.number', { number: index + 1 }) }}
              </div>
            </div>
          </div>
          <div class="flex-grow-1 mt-auto px-4 pt-4 pt-lg-9 pb-4 pb-lg-6">
            <template v-for="n in module.chapters_done">
              <div
                :key="'done' + n"
                class="d-inline-block chapters-circle primary mr-2 my-1 my-md-2"
              ></div>
            </template>
            <template v-for="n in module.chapters_count - module.chapters_done">
              <div
                :key="'count' + n"
                class="d-inline-block chapters-circle secondary tertiary mr-2 my-1 my-md-2"
              ></div>
            </template>
            <div class="d-flex flex-wrap justify-space-between">
              <div class="text-h6 text-body-medium primary--text">
                {{ $t('module.completion', { done: module.chapters_done, total: module.chapters_count }) }}
              </div>
              <v-btn
                tile
                depressed
                color="secondary"
                :to="{ name: 'chapter', params: { chapterId: module.first_chapter }}"
              >{{ $t('general.continue') }}</v-btn>
            </div>
          </div>
        </v-sheet>
      </v-hover>
    </template>
  </div>
</template>

<style lang="scss" scoped>

.chapters-circle {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

</style>

<script>

export default {
  props: ['modules'],
  data() {
    return {
      moduleProgress: 30,
    }
  }
}

</script>
