<template>
  <div class="tertiary fill-height">
    <v-container>
      <v-row class="py-0">
        <v-col cols="12" lg="10" offset-lg="1">
          <page-banner>
            <template v-slot:subheader>{{ $t('evaluations.sub_header') }}</template>
            <template v-slot:page-title>{{ course.title }}</template>
          </page-banner>
          <course-nav :courseId="courseId" :can_access="course.can_access"></course-nav>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" lg="10" offset-lg="1">
          <review-list :reviews="reviews" :courseId="courseId" v-if="reviews != false"></review-list>
          <evaluation-list :evaluations="evaluations" :courseId="course.id" :can_access="course.can_access">
          </evaluation-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import EvaluationList from "@/components/evaluation/List";
import PageBanner from "@/components/PageBanner";
import CourseNav from "@/components/course/Nav.vue";
import ReviewList from "@/components/review/List";

export default {
  components: {
    EvaluationList,
    PageBanner,
    CourseNav,
    ReviewList,
  },
  data() {
    return {
      course: false,
      evaluations: false,
      reviews: false,
    };
  },
  mounted() {
    this.getCourse();
  },
  computed: {
    courseId() {
      return this.$route.params.courseId;
    },
    can_access() {
      return this.$route.params.can_access
    },
  },
  methods: {
    getCourse() {
      axios
        .get(this.$root.$options.api.url + '/courses/' + this.courseId)
        .then(response => {
          this.course = response.data.course
          this.modules = response.data.modules
          this.evaluations = response.data.evaluations
          this.reviews = response.data.reviews
        })
        .catch(() => {
          this.$router.push({
            name: "home",
          });
        })
    },
  },
};
</script>
