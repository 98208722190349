<template>
  <v-col cols="12" lg="10" offset-lg="1" class="pb-2">
    <v-hover v-slot:default="{ hover }">
      <v-sheet
        min-height="115"
        class="d-sm-flex"
        :elevation="hover ? 2 : 0"
      >
        <div
          class="teaser__sm-header teaser-header-bg d-flex px-3 py-3"
        >
         <h3 class="text-h5 primary--text my-auto">{{ course.title }}</h3>
        </div>
        <div class="flex-grow-1 d-flex">
          <div class="pa-3 d-flex flex-column align-start">
            <p class="mb-3 line-clamp-2">
              {{ course.teaser }}
            </p>
            <div class="mr-1 mt-auto">
              <v-btn depressed small primary @click.stop="dialog = true">{{ $t('teaser.notify_me') }}</v-btn>
            </div>
          </div>
          <div class="px-3 py-3 my-auto ml-auto">
             <div class="coming-soon--stamp">
            <span>{{ $t('teaser.soon') }}</span>
          </div>
          </div>
        </div>
      </v-sheet>
    </v-hover>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-sheet scrollable tile>
        <v-img src="@/assets/bg-secondary.svg" style="min-height:100vh" cover>
          <v-responsive>
            <div class="tertiary pt-16 pb-4 pb-sm-6">
              <v-container class="flex-grow-1">
                <div class="d-flex flex-wrap justify-space-between align-end">
                  <div>
                    <v-btn
                      @click.stop="dialog = false"
                      color="secondary"
                      outlined
                    >
                      <v-icon left>mdi-close</v-icon>
                      {{ $t('general.close') }}</v-btn
                    >
                    <div class="paragraph-title primary--text mt-8">
                      {{ course.title }}
                    </div>
                    <h4 class="secondary--text text-h5">
                      {{ $t('teaser.notify_me.message') }}
                    </h4>
                  </div>
                </div>
              </v-container>
            </div>
            <v-container>
                  <v-card tile max-width="450" class="mx-auto mt-10 mt-lg-16">
                    <v-card-title
                      class="text-body-medium blue-grey--text grey lighten-4"
                    >
                      {{ $t('teaser.notify_me.my_email') }}
                    </v-card-title>
                    <v-card-text class="py-6 py-lg-8">
                      <v-text-field
                        :label="$t('general.email_label')"
                        v-model="email"
                        type="email"
                        :error-messages="errors.get('email')"
                      ></v-text-field>
                      <v-btn
                        color="primary"
                        depressed
                        block
                        x-large
                        @click="registerEmail()"
                        :loading="working"
                        >{{ $t('general.save') }}</v-btn
                      >
                    </v-card-text>
                  </v-card>
            </v-container>
          </v-responsive>
        </v-img>
      </v-sheet>
    </v-dialog>
  </v-col>
</template>

<style lang="scss" scoped>

.coming-soon--stamp {
  display: inline-flex;
  align-items: center;
  text-align: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #d8e020;
}

.coming-soon--stamp span {
  content: "Bientôt disponible";
  line-height: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #204e81;
}
</style>

<script>
import axios from "axios";
import Errors from "@/tools/errors";

export default {
  props: ["course"],
  data() {
    return {
      dialog: false,
      email: '',
      errors: new Errors(),
      working: false,
    };
  },
  methods: {
    registerEmail() {
      this.working = true;
      axios
        .post(this.$root.$options.api.url + "/courses/notify-available", {
          email: this.email,
          course_id: this.course.id
        })
        .then(response => {
          if (response.data.errors) {
            this.working = false;
            this.errors.record(response.data.errors)
          } else {
            this.$router.go()
          }
        })
        .catch(error => {
          this.working = false;
          this.errors.record(error.response.data.errors)
        })
    }
  }
};
</script>
