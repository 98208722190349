<template>
  <div>
    <template v-for="(review, index) in reviews">
      <v-hover v-if="review.type == 'module' || review.public" :key="index" v-slot:default="{ hover }">
        <v-card
          link
          :ripple="false"
          :class="['mb-10', { 'on-hover': hover }]"
          :elevation="hover ? 5 : 2"
          :to="{ name: 'review', params: { reviewId: review.id } }"
        >
          <div class="px-4 py-4">
            <!--<div class="primary--text text-overline">Review</div>-->
            <h2 class="text-h5 primary--text mb-10">
              {{ review.name }}
            </h2>
            <v-btn
              color="secondary"
              tile
              small
              depressed
              :to="{ name: 'review', params: { reviewId: review.id } }"
              >{{ $t('study_notes.link') }}
            </v-btn>
          </div>
        </v-card>
      </v-hover>
      <v-card
        v-if="review.type == 'course' && !review.public && !review.evaluation"
        :key="index"
        :ripple="false"
        :class="['mb-10']"
      >
        <div class="px-4 py-4">
          <div class="d-flex flex-wrap justify-space-between align-center mb-3">
            <div class="grey--text text--darken-1 text-body-1 ">{{ $t('study_notes.date', { date: review.date }) }}</div>
          </div>
          <h2 class="text-h5 primary--text mb-6">
            {{ review.name }}
          </h2>
            <v-btn
             color="secondary"
              tile
              small
              depressed
            :to="{ name: 'review', params: { reviewId: review.id } }"
            >{{ $t('study_notes.link') }}
          </v-btn>
        </div>
      </v-card>
      <v-hover v-if="review.evaluation && !review.public" :key="index" v-slot:default="{ hover }">
        <v-card
          :key="index"
          :ripple="false"
          :class="['mb-10', { 'on-hover': hover }]"
          :elevation="hover ? 5 : 2"
          :to="{ name: 'review-evaluation', params: { reviewId: review.id, courseId: courseId }}"
        >
          <div class="px-4 py-4">
            <h2 class="text-h5 primary--text mb-6">
              {{ review.name }}
            </h2>
              <v-btn
              tile
              depressed
              small
              color="primary"
              :to="{ name: 'review-evaluation', params: { reviewId: review.id, courseId: courseId }}"
              >{{ $t('evaluations.start') }}
            </v-btn>
          </div>
        </v-card>
      </v-hover>
    </template>
  </div>
</template>

<script>
export default {
  props: ["reviews", "courseId"],
  methods: {
    emitDeleteReview(id) {
      this.$emit('delete-review', id )
    }
  }
};
</script>
