<template>
  <div class="fill-height">
    <div class="tertiary">
      <v-container>
        <v-row>
          <v-col cols="12" lg="10" offset-lg="1">
            <v-card flat tile class="transparent mt-8 mt-md-12 mt-lg-16 mb-2">
              <v-btn
                :to="{
                  name: 'course-reviews',
                  params: { courseId: course.id },
                }"
                outlined
                small
                color="primary"
                class="mb-6 mb-lg-10"
              >
                <v-icon left> mdi-arrow-left</v-icon>
                {{ $t('study_notes.title') }}
              </v-btn>
              <h2 class="font-title-2 sm__font-title-2 primary--text mb-3">
                {{ review.name }}
              </h2>
              <div class="text-h6" v-if="review.type == 'course' && !review.public">
                {{ $t('study_notes.date', { date: review.date }) }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" lg="9" offset-lg="1">
          <template v-for="(chapter, index) in chapters">
            <div :key="'chapter_' + index">
              <h2 class=" font-title-2 underlined primary--text mt-md-16 mb-2">
              {{ chapter.title }}
              </h2>
            </div>
            <template v-for="(content, index) in contents">
              <div
                v-if="content.chapter_id == chapter.chapter_id"
                :key="'content_' + index"
              >
                <content-type :content="transformContentType(content)"></content-type>
              </div>
            </template>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import ContentType from "@/components/content/Type";

export default {
  components: {
    ContentType,
  },
  data() {
    return {
      review: false,
      contents: [],
      chapters: [],
      course: false,
    };
  },
  computed: {
    reviewId() {
      return this.$route.params.reviewId;
    },
  },
  mounted() {
    this.getReview();
  },
  methods: {
    getReview() {
      axios
        .get(this.$root.$options.api.url + "/reviews/" + this.reviewId)
        .then((response) => {
          this.review = response.data.review;
          this.contents = this.review.contents;
          this.chapters = this.review.chapters;
          this.course = this.review.course;

          if (this.review.public || this.review.type == 'course') {
            this.contents.forEach(content => {
              content.addReview = false;
            });
          }
        });
    },
    transformContentType(content) {
      let types = [
        'definition',
        'information',
        'attention',
        'laws'
      ]

      if (types.includes(content.type)) {
        content.type = 'text'
      }

      return content
    },
  },
};
</script>
