<template>
  <div>
<v-container>
  <v-row>
    <v-col cols="12" lg="8" offset-lg="2">
      <div v-html="$t('general_conditions.content')" />
   </v-col>
  </v-row>
</v-container>
  </div>
</template>

<style lang="scss" scoped>
  h2 {
    margin-top: 2rem;
  }
</style>

<script>
  export default {
    name: "cgv",

  }
</script>

<style lang="scss" scoped>

</style>
