<template>
  <div class="tertiary fill-height">
    <v-container>
      <page-banner>
        <template v-slot:subheader>{{ $t('tags.sub_header') }}</template>
        <template v-slot:page-title>{{ $t('tags.title') }}</template>
      </page-banner>
      <Nav></Nav>
      <v-row>
        <v-col cols="12" lg="8" offset-lg="2">
          <div v-if="contents.length == 0" class="text-primary text-center mt-4">
          <p class="text-h6">{{ $t('tags.no_tags') }}</p>
          <p>{{ $t('tags.no_tags.description') }}</p>

          </div>
          <template v-for="(content, index) in contents">
            <content-type
              :key="'content_' + index"
              :content="content"
            ></content-type>
          </template>
          <div class="text-center my-12">
            <v-btn
            v-if="contents.length != 0"
              :color="'primary'"
              x-large
              class="mt-4"
              @click="storeReview()"
              :loading="working"
              >{{ $t('tags.study_notes') }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import Errors from "@/tools/errors";
import ContentType from "@/components/content/Type";
import PageBanner from '@/components/PageBanner.vue';
import Nav from '@/components/course/Nav.vue';
export default {
  data() {
    return {
      contents: false,
      working: false,
      errors: new Errors(),
      review: false,
    };
  },
  components: {
    ContentType,
    PageBanner,
    Nav
  },
  mounted() {
    this.tags();
  },
  methods: {
    tags() {
      axios
        .get(this.$root.$options.api.url + "/contents/tags")
        .then((response) => {
          this.contents = response.data.contents;
        });
    },
    storeReview() {
      this.working = true;
      axios
        .post(this.$root.$options.api.url + "/reviews")
        .then((response) => {
          this.review = response.data.review;

          this.$router.push({
            name: "review",
            params: {
              reviewId: this.review.id,
            },
          });
        })
        .catch((error) => {
          this.working = false;
          this.errors.record(error.response.data.errors);
        });
    },
  },
};
</script>
