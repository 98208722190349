<template>
  <div>
    <v-container class="py-0">
      <v-row class="py-0">
        <v-col cols="12">
          <PageBanner />
          <v-tabs class="tabs-border-bottom" height="55">
            <v-tab link :to="{ name: 'lessons' }">Modules</v-tab>
            <v-tab link :to="{ name: 'chapter-quiz' }">Evaluations</v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <!-- Résumé !-->
          <div class="text-center">
            <p class="text-overline mb-0">Evaluation du 22.06.2022</p>
            <h2 class="mb-2">Felicitation Charles-Henri Deschenaux</h2>
            <p class="green--text">48 / 50 bonnes réponse</p>
            <v-btn color="primary" outlined>Recommencer</v-btn>
          </div>
        </v-col>
        <v-col cols="12" lg="8" offset-lg="2">
        </v-col>
        <v-col>
          <!-- question -->
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="8" offset-lg="2">
          <p class="text-overline text-center mb-0">Questions</p>
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="questionsSum"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>

          <v-card
            :key="index"
            max-width="100%"
            outlined
            class="mx-auto pb-6 mt-6 mb-10"
          >
            <v-img
              src="https://cdn.vuetifyjs.com/images/cards/mountain.jpg"
              :aspect-ratio="16 / 9"
            ></v-img>
            <v-list-item class="py-3 mb-3 tertiary">
              <v-list-item-content class="headline">{{
                question.title
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="mr-2">
                <v-icon color="green">mdi-checkbox-marked-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="green--text"
                >La puissance propulsive dépasse 6 kw (4,4 kw sur le lac de
                Constance).</v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="mr-2">
                <v-icon color="grey lighten-1"
                  >mdi-checkbox-blank-outline</v-icon
                >
              </v-list-item-icon>
              <v-list-item-content
                >La surface vélique, calculée selon l’annexe 12, est de plus de
                15 m2 (12 m2 sur le lac de Constance). chargééé les
                canons</v-list-item-content
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="mr-2">
                <v-icon color="red">mdi-checkbox-marked-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="red--text"
                >La réponse D</v-list-item-content
              >
            </v-list-item>
          </v-card>

          <v-card
            :key="index"
            max-width="100%"
            outlined
            class="mx-auto pb-6 mt-6 mb-10"
          >
            <v-img
              src="https://cdn.vuetifyjs.com/images/cards/mountain.jpg"
              :aspect-ratio="16 / 9"
            ></v-img>
            <v-list-item class="py-3 mb-3 tertiary">
              <v-list-item-content class="headline">{{
                question.title
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-checkbox
                v-model="userAnswers"
                value="answer1"
                class="mr-2 mt-0"
              >
                <template v-slot:label>
                  <div class="grey--text text--darken-4">
                    La puissance propulsive dépasse 6 kw (4,4 kw sur le lac de
                    Constance)
                  </div>
                </template>
              </v-checkbox>
            </v-list-item>
            <v-list-item>
              <v-checkbox v-model="userAnswers" value="answer2" class="mr-2">
                <template v-slot:label class="align-start">
                  <div class="grey--text text--darken-4">
                    La surface vélique, calculée selon l’annexe 12, est de plus
                    de 15 m2 (12 m2 sur le lac de Constance)
                  </div>
                </template>
              </v-checkbox>
            </v-list-item>
            <v-list-item>
              <v-checkbox
                v-model="userAnswers"
                value="answer3"
                class="mr-2 mt-0"
              >
                <template v-slot:label>
                  <div class="grey--text text--darken-4">La réponse D</div>
                </template>
              </v-checkbox>
            </v-list-item>
          </v-card>

          <p class="text-overline mb-0">Les plans d'eau</p>
          <h2 class="text-h4 primary--text mb-4">Géographie</h2>
          <div class="d-flex flex-wrap align-start justify-space-between">
            <v-btn small depressed color="tertiary" class="mb-4">
              <v-icon left>mdi-bookmark-plus-outline</v-icon>Ajouter à mes
              cartes
            </v-btn>
          </div>

          <p class="chapter-paragraph">
            Bienvenue dans ce premier module. Nous tenterons de vous apprendre
            les bases de la compréhension des différents plans d’eaux en Suisse.
            Par exemple les règles générales de pratique, la signalisation,
            comment se comporter avec les autres usagers qu’ils soient
            navigateurs, pêcheurs, wakeboarders ou simplement nageurs. Nous
            prendrons un moment pour comprendre les principaux phénomènes
            météorologiques.
          </p>
          <p class="chapter-paragraph chapter-info">
            La Suisse compte plus de 1500 lacs, certains artificiels. La surface
            totale recouverte par les eaux est d’environ 4% du territoire et la
            Suisse contient 6% des réserves d’eau en Europe.
          </p>

          <div class="text-center mt-10">
            <v-pagination
              v-model="page"
              :length="questionsSum"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import axios from "axios";
import PageBanner from "@/components/PageBanner.vue";

export default {
  name: "ChapterQuiz",
  props: ["moduleId"],
  components: {
    PageBanner,
  },
  data() {
    return {
      question: false,
      userAnswers: [],
      questionsSum: 20,
      page: 1,
    };
  },
  mounted() {
    this.getQuestion();
  },
  methods: {
    getQuestion() {
      axios
        .get(this.$root.$options.api.url + "/questions?id=1")
        .then((response) => {
          this.question = response.data[0];
        })
        .catch((error) => {
          console.log("there was an errot", error.response);
        });
    },
  },
};
</script>
