<template>
  <div>
    <div class="tertiary">
      <v-container class>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-subheader class="justify-center pl-0">chapitre 1</v-subheader>
            <h1 class="font-title-2 primary--text text-center mb-6">
              Les catégories de permis
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container>
      <v-stepper class="elevation-0">
        <v-row>
          <v-col cols="12" lg="8" offset-lg="2">
            <div class="d-flex justify-space-between">
              <v-btn
                :to="{ name: 'lessons' }"
                exact
                text
                color="primary"
                class="mr-auto"
              >
                <v-icon dense class="mr-1">mdi-chevron-left</v-icon>Précédent
              </v-btn>
              <v-btn
                :to="{ name: 'lessons' }"
                exact
                text
                color="primary"
                class="ml-auto"
              >
                Suivant
                <v-icon dense class="ml-1">mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <v-tabs class="boat-pagination mt-8 mb-8" center-active grow>
              <v-tab>
                <div class="boat-pagination__icon"></div>
                <div class="boat-pagination__line"></div>
              </v-tab>
              <v-tab>
                <div class="boat-pagination__icon"></div>
                <div class="boat-pagination__line"></div>
              </v-tab>
              <v-tab>
                <div class="boat-pagination__icon"></div>
                <div class="boat-pagination__line"></div>
              </v-tab>
              <v-tab>
                <div class="boat-pagination__icon"></div>
                <div class="boat-pagination__line"></div>
              </v-tab>
              <v-tab>
                <div class="boat-pagination__icon"></div>
                <div class="boat-pagination__line"></div>
              </v-tab>
              <v-tab>
                <div class="boat-pagination__icon"></div>
                <div class="boat-pagination__line"></div>
              </v-tab>
              <v-tab>
                <div class="boat-pagination__icon"></div>
                <div class="boat-pagination__line"></div>
              </v-tab>
              <v-tab>
                <div class="boat-pagination__icon"></div>
                <div class="boat-pagination__line"></div>
              </v-tab>
              <v-tab>
                <div class="boat-pagination__icon"></div>
                <div class="boat-pagination__line"></div>
              </v-tab>
            </v-tabs>

            <!-- Contenu du chapitre !-->
            <div class="chapter-container mt-12">
              <div class="chapter-paragraph">
                <div class="d-flex flex-wrap align-start justify-space-between">
                  <h2 class="text-h4 primary--text mb-4">Introduction</h2>
                  <v-btn small depressed color="tertiary" class="mb-4">
                    <v-icon left>mdi-bookmark-plus-outline</v-icon>Ajouter à mes
                    cartes
                  </v-btn>
                </div>
                <p>
                  Bienvenue dans ce premier module. Nous tenterons de vous
                  apprendre les bases de la compréhension des différents plans
                  d’eaux en Suisse. Par exemple les règles générales de
                  pratique, la signalisation, comment se comporter avec les
                  autres usagers qu’ils soient navigateurs, pêcheurs,
                  wakeboarders ou simplement nageurs. Nous prendrons un moment
                  pour comprendre les principaux phénomènes météorologiques.
                </p>
              </div>
              <div class="chapter-paragraph chapter-framed framed-info__color">
                <v-icon color="white" class="framed-icon"
                  >mdi-information-outline</v-icon
                >
                <h3>Information</h3>
                <p>
                  Ce module va vous enseigner les bases de la connaissance des
                  plans d’eau Suisses. Le but est qu’à la fin du cours :
                </p>
                <ul>
                  <li>
                    Le lac Léman partagé avec la France (RNL 0.747.221.11)
                  </li>
                  <li>
                    Le lac Léman partagé avec la France (RNL 0.747.221.11), Le
                    lac Léman partagé avec la France (RNL 0.747.221.11)
                  </li>
                </ul>
              </div>
              <div class="chapter-paragraph">
                <div class="d-flex flex-wrap align-start justify-space-between">
                  <h3>List example</h3>
                  <v-btn small depressed color="tertiary" class="mb-4">
                    <v-icon left>mdi-bookmark-plus-outline</v-icon>Ajouter à mes
                    cartes
                  </v-btn>
                </div>
                <ul>
                  <li>list item 1</li>
                  <li>
                    Le lac Léman partagé avec la France (RNL 0.747.221.11), Le
                    lac Léman partagé avec la France (RNL 0.747.221.11)
                  </li>
                </ul>
              </div>
              <div class="chapter-paragraph chapter-framed framed-alert__color">
                <v-icon color="white" class="framed-icon"
                  >mdi-alert-octagon-outline</v-icon
                >
                <h3>Attention</h3>
                <p>
                  Ce module va vous enseigner les bases de la connaissance des
                  plans d’eau Suisses. Le but est qu’à la fin du cours :
                </p>
                <ul>
                  <li>
                    Le lac Léman partagé avec la France (RNL 0.747.221.11)
                  </li>
                  <li>
                    Le lac Léman partagé avec la France (RNL 0.747.221.11), Le
                    lac Léman partagé avec la France (RNL 0.747.221.11)
                  </li>
                </ul>
              </div>

              <div
                class="chapter-paragraph chapter-framed framed-definition__color"
              >
                <v-icon color="white" class="framed-icon"
                  >mdi-book-open-blank-variant</v-icon
                >
                <h3>Definition</h3>
                <p>
                  Ce module va vous enseigner les bases de la connaissance des
                  plans d’eau Suisses. Le but est qu’à la fin du cours :
                </p>
                <ul>
                  <li>
                    Le lac Léman partagé avec la France (RNL 0.747.221.11)
                  </li>
                  <li>
                    Le lac Léman partagé avec la France (RNL 0.747.221.11), Le
                    lac Léman partagé avec la France (RNL 0.747.221.11)
                  </li>
                </ul>
              </div>

              <div class="chapter-paragraph chapter-framed framed-error__color">
                <v-icon color="white" class="framed-icon">mdi-cancel</v-icon>
                <h3>Erreur fréquente</h3>
                <p>
                  Ce module va vous enseigner les bases de la connaissance des
                  plans d’eau Suisses. Le but est qu’à la fin du cours :
                </p>
                <ul>
                  <li>
                    Le lac Léman partagé avec la France (RNL 0.747.221.11)
                  </li>
                  <li>
                    Le lac Léman partagé avec la France (RNL 0.747.221.11), Le
                    lac Léman partagé avec la France (RNL 0.747.221.11)
                  </li>
                </ul>
              </div>

              <div class="chapter-paragraph chapter-framed framed-law__color">
                <v-icon color="white" class="framed-icon">mdi-gavel</v-icon>
                <h3>Lois</h3>
                <p>
                  Ce module va vous enseigner les bases de la connaissance des
                  plans d’eau Suisses. Le but est qu’à la fin du cours :
                </p>
                <ul>
                  <li>
                    Le lac Léman partagé avec la France (RNL 0.747.221.11)
                  </li>
                  <li>
                    Le lac Léman partagé avec la France (RNL 0.747.221.11), Le
                    lac Léman partagé avec la France (RNL 0.747.221.11)
                  </li>
                </ul>
              </div>

              <div class="chapter-paragraph chapter-img text-center">
                <figure>
                  <img src="@/assets/img-chapter-xl.jpg" alt />
                  <figcaption class="text-subtitle-2">
                    Fig.1 - carte des régions Suisse
                  </figcaption>
                </figure>
              </div>

              <div class="chapter-paragraph">
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Enim
                  blanditiis, nihil, voluptate ex temporibus aliquam architecto
                  veniam consequuntur asperiores alias pariatur, voluptas
                  facilis beatae delectus qui cum vel doloribus omnis.
                </p>
              </div>

              <div class="chapter-paragraph chapter-img text-center">
                <figure>
                  <img src="@/assets/img-chapter-sm.jpg" alt />
                  <figcaption class="text-subtitle-2">
                    Fig.1 - carte des régions Suisse
                  </figcaption>
                </figure>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="text-center my-12">
              <v-btn color="success" x-large class="mt-4"
                >J'ai terminé ce chapitre !</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
//import axios from "axios";

export default {
  name: "ChapterTraining",
  props: ["chapterId"],
  data() {
    return {
      questions: false,
      page: 3,
      steps: 8,
    };
  },
};
</script>
