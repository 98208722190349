<template>
  <v-card style="margin:0 auto !important">
    <v-card-title class="grey lighten-4">
        <div class="mx-auto">
         <h2 v-if="success" class="text-h5 success--text text-center mb-3">
           {{ $t('evaluations.success') }}
        </h2>
        <div v-else>
          <h2 class="text-h5 success-text text-center mb-0">
            {{ $t('evaluations.failure') }}
        </h2>
        <p class="text-body-2 error--text text-center mb-0">{{ $t('evaluations.failure_points', { points: (goodAnswers + badAnswers - maxErrors)}) }}</p>
        </div>
        </div>
    </v-card-title>
    <v-card-text>
        <div class="d-flex justify-center text-center mt-4 mb-6">
          <div class="">{{ $t('evaluations.failed_questions') }}
        <div class="text-h5">
          <span class="error--text">{{ badQuestions || 0 }}</span>
        </div>
        </div>
        <div class="border mx-2"></div>
        <div class="">{{ $t('evaluations.points') }}
        <div class="text-h5">
          <span class="">{{ goodAnswers || 0 }}</span>
          <span class=""> / </span>
          <span>{{ goodAnswers + badAnswers }}</span>
        </div>
        </div>
        </div>

        <div class=" d-flex flex-wrap justify-center">
        <template v-for="(question, index) in questions">
          <v-btn
            :key="question.id"
            depressed
            class="mx-2 my-1 my-md-2"
            :class="{
              'success': question.is_correct,
              'error': !question.is_correct,
            }"
            @click="showQuestion(index)"
          >{{ index + 1 }}
          </v-btn>
        </template>
        </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text large @click="EmitCloseBtn">{{ $t('general.close') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
 .border {
   border-left: 1px solid lightgrey;
 }
</style>

<script>
export default {
  props: [
    'goodAnswers',
    'badAnswers',
    'goodQuestions',
    'badQuestions',
    'maxErrors',
    'success',
    'questions',
  ],
    methods: {
    EmitCloseBtn() {
      this.$emit('close-dialog', { dialog: false })
    },
     showQuestion(index) {
      this.$emit('showQuestion', index);
      this.EmitCloseBtn()
    },
  },
};
</script>
