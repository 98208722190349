<template>
  <div>
    <h1>{{ $t('checkout.description', { description: orderDetail.description }) }}</h1>
    <h2>{{ $t('checkout.price', { price: orderDetail.price }) }}</h2>
    <p>{{ $t('checkout.fullPrice', { price: priceTaxe }) }}</p>
    <label>Card</label>
    <div id="card-element">
    </div>

    <v-btn
      class="btn btn-primary mt-3"
      id="add-card-button"
      @click="submitPaymentMethod()"
      :loading="working"
      >
      {{ $t('checkout.payment_method.submit') }}
    </v-btn>

  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      course: false,
      orderDetail: false,
      stripeAPIToken: 'pk_test_4XPf4oa6V0pSjxKEgjGBx4Nj00d3I6OETl',
      stripe: '',
      elements: '',
      card: '',
      intentToken: '',
      addPaymentStatusError: '',
      priceTaxe: 0,
      working: false,
    }
  },
  computed: {
    orderId() {
      return this.$route.params.orderId;
    },
    courseId() {
      return this.$route.params.courseId;
    }
  },
  mounted() {
    this.getCourse();
    this.getOrder();
    this.configureStripe();

    this.loadIntent();
  },
  methods: {
    getCourse() {
      axios
        .get(this.$root.$options.api.url + "/courses/" + this.courseId)
        .then((response) => {
          this.course = response.data.course;
          if (this.course.can_access || this.course.free) {
            this.$router.push({
              name: "course",
              params: {
                courseId: this.course.id,
              }
            });
          }
        })
    },
    getOrder() {
      axios
        .get(this.$root.$options.api.url + "/user/order/" + this.orderId)
        .then((response) => {
          this.orderDetail = response.data.order_detail;
          this.priceTaxe = response.data.price_taxes;
        });
    },
    configureStripe(){
      this.stripe = window.Stripe( this.stripeAPIToken );

      this.elements = this.stripe.elements();
      this.card = this.elements.create('card', {
        hidePostalCode: true,
      });

      this.card.mount('#card-element');
  },
    loadIntent(){
      axios
        .get(
          this.$root.$options.api.url + "/user/setup-intent"
        )
        .then(function(response){
          this.intentToken = response.data;
        }.bind(this));
    },
    submitPaymentMethod(){
      this.working = true;
      this.stripe.confirmCardSetup(
        this.intentToken.client_secret, {
          payment_method: {
            card: this.card,
          }
        }
      ).then(function(result) {
        if (result.error) {
          this.addPaymentStatusError = result.error.message;
          this.working = false;
        } else {
          this.checkout(result.setupIntent.payment_method);
          this.card.clear();
        }
      }.bind(this));
    },
    checkout(method) {
      axios
        .post(
          this.$root.$options.api.url + "/courses/checkout-charge", {
            payment_method: method,
            order_id: this.orderId,
            course: this.course,
          })
        .then(() => {
          this.$router.push({
            name: "home",
          });
        });
    },
  },
};
</script>
