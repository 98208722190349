<template>
  <div>
    <!-- hidden on mobile !-->
    <div class="d-flex align-end">
      <v-sheet max-width="460" class="ml-auto">
        <v-tabs
          class="boat-pag d-none d-sm-flex"
          hide-slider
          mobile-breakpoint=""
          height="64"
          background-color="tertiary"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
        >
          <template v-for="(chapter, index) in chapters">
            <v-tooltip
              :key="'chapter_' + index"
              top
              color="primary"
              transition="none"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  class="boat-pag__tab"
                  :v-ripple="false"
                  v-bind="attrs"
                  v-on="on"
                  :to="{ name: 'chapter', params: { chapterId: chapter.id } }"
                >
                  <div class="boat-pag__icon"></div>
                  <div
                    class="boat-pag__circle"
                    :class="{ success: chapter.done }"
                  ></div>
                </v-tab>
              </template>
              <span>{{ index + 1 }}: {{ chapter.title }} </span>
            </v-tooltip>
          </template>
        </v-tabs>
      </v-sheet>
    </div>
    <!-- show for mobile !-->
    <v-expansion-panels v-model="openedPanel" accordion flat class="d-sm-none mt-4 mb-4">
      <v-expansion-panel style="border: 1px solid lightgrey">
        <v-expansion-panel-header class="px-3">
          {{ $t('chapter.list') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mx-n5">
          <v-tabs vertical hide-slider :fixed-tabs="false" class="boat-pag">
            <template v-for="(chapter, index) in chapters">
              <v-tab
                :key="index"
                :to="{ name: 'chapter', params: { chapterId: chapter.id } }"
                @click="openedPanel = false"
                class="pag-vertical"
              >
                <div class="pag-vertical__wrapper" style="position: relative">
                  <div class="boat-pag-vertical__icon"></div>
                  <div
                    class="boat-pag-vertical__circle"
                    :class="{ success: chapter.done }"
                  ></div>
                </div>

                <div class="text-capitalize">
                  {{ index + 1 }}: {{ chapter.title }}
                </div>
              </v-tab>
            </template>
          </v-tabs>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: ["chaptersCount", "chaptersDone", "chapters", "chapterId"],
  data() {
    return {
      openedPanel: false,
    }
  }
};
</script>

<style lang="scss" scoped>
//_pagination.scss
</style>
