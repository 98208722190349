<template>
  <div class="tertiary fill-height">
    <v-container>
      <v-row class="py-0">
        <v-col cols="12">
          <page-banner>
            <template v-slot:subheader>{{ $t('purchase.sub_header') }}</template>
            <template v-slot:page-title>{{ $t('purchase.title') }}</template>
          </page-banner>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col cols="12" xl="5" class="order-lg-2">
          <v-card tile>
            <v-card-title
              class="text-body-medium blue-grey--text grey lighten-4"
            >
              {{ $t('purchase.my_order') }}
            </v-card-title>
            <v-card-text class="py-6 py-lg-8">
              <v-list>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6 mb-1">
                      {{ course.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <div class="">{{ course.price }}</div>
                </v-list-item>
                <v-list-item v-if="code_verified" class="px-0 text-valid">
                  <v-list-item-content>
                    <v-list-item-title class="mb-1">
                      {{ $t('purchase.coupon_name', { coupon_name }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <div class="">-{{ discount }}</div>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title class="mb-1">
                      {{ $t('purchase.transaction_fees') }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <div class="">{{ taxe }}</div>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title class="mb-1">
                      <v-text-field
                        :label="$t('purchase.coupon')"
                        v-model="code"
                        type="text"
                        :error-messages="errors.get('coupon')"
                        @input="errors.clear('coupon')"
                      ></v-text-field>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-btn
                    color="primary"
                    outlined
                    small
                    class="ml-2"
                    @click="coupon()"
                    :loading="workingCoupon"
                    >{{ $t('purchase.coupon.submit') }}</v-btn
                  >
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <div
                class="d-flex justify-space-between align-center mt-6 mt-lg-8"
              >
                <div class="text-h5 black--text">{{ $t('purchase.total') }}</div>
                <div class="text-h5 black--text">
                  {{ final_price_taxe_formatted }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xl="7" class="order-lg-1">
          <v-card class="mb-6">
            <v-card-title
              class="text-body-medium blue-grey--text grey lighten-4"
            >
              {{ $t('purchase.details') }}
            </v-card-title>
            <v-card-text class="py-6 py-lg-8">
              <v-row>
                <v-col cols="12" sm="6" class="d-sm-flex align-start">
                  <v-icon color="blue-grey" class="mr-3 mb-3 mb-sm-0"
                    >mdi-account-circle
                  </v-icon>
                  <div class="text-h6 primary--text mb-0">
                    <p class="mb-0">{{ user.first_name }}</p>
                    <p class="mb-0">{{ user.last_name }}</p>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" class="d-sm-flex align-start">
                  <v-icon color="blue-grey" class="mr-3 mb-3 mb-sm-0"
                    >mdi-home-circle
                  </v-icon>
                  <div  class="text-h6 primary--text mb-0">
                    <p class="mb-0">{{ user.address }}</p>
                    <p class="mb-0">{{ user.zip }}, {{ user.locality }}</p>
                  </div>
                </v-col>
              </v-row>
              <v-btn
                small
                depressed
                class="mt-4"
                outlined
                :to="{ name: 'user-course', params: { courseId: courseId }}"
                >{{ $t('purchase.details.edit') }}</v-btn
              >
            </v-card-text>
          </v-card>
          <v-card v-show="showCheckout">
            <v-card-title
              class="text-body-medium blue-grey--text grey lighten-4"
            >
              {{ $t('purchase.payment_method') }}
            </v-card-title>
            <v-card-text class="py-6 py-lg-8">
              <div
                id="card-element"
                class="card-field mt-4 mt-lg-8 pb-2 mb-8 mb-lg-8"
                :error-messages="errors.get('card')"
              ></div>
              <v-checkbox
                v-model="checkboxCGV"
                :v-ripple="false"
                class="mt-0"
                :error-messages="errors.get('cgv')"
              >
                <template v-slot:label>
                  <p class="text-body-1 black--text">
                    {{ $t('purchase.general_conditions.label') }}
                    <a
                      href="javascript: void(0)"
                      @click.stop="dialog = true"
                    >
                      {{ $t('purchase.general_conditions') }}
                    </a>
                  </p>
                </template>
              </v-checkbox>
              <v-btn
                color="primary"
                depressed
                block
                x-large
                @click="postOrder()"
                :loading="working"
                >{{ $t('purchase.buy') }}</v-btn
              >
            </v-card-text>
          </v-card>
          <v-card v-show="!showCheckout">
            <v-card-text class="py-6 py-lg-8">
              <v-checkbox
                v-model="checkboxCGV"
                :v-ripple="false"
                class="mt-0"
                :error-messages="errors.get('cgv')"
              >
                <template v-slot:label>
                  <p class="text-body-1 black--text">
                    {{ $t('purchase.general_conditions.label') }}
                    <a
                      href="javascript: void(0)"
                      @click.stop="dialog = true"
                    >
                      {{ $t('purchase.general_conditions') }}
                    </a>
                  </p>
                </template>
              </v-checkbox>
              <v-btn
                color="primary"
                depressed
                block
                x-large
                @click="postOrder()"
                :loading="working"
                >{{ $t('purchase.unlock') }}</v-btn
              >
            </v-card-text>
          </v-card>

          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-sheet scrollable tile >
              <v-responsive>
                <div class="tertiary pt-16 pb-4 pb-sm-6">
                  <v-container class="flex-grow-1">
                    <div class="d-flex flex-wrap justify-space-between align-end">
                      <div>
                        <v-btn
                          @click.stop="dialog = false"
                          color="secondary"
                          outlined
                        >
                          <v-icon left>mdi-close</v-icon>
                          {{ $t('general.close') }}</v-btn
                        >
                        <h1 class="font-title-2 sm__font-title-2 primary--text mt-8">
                          {{ $t('purchase.general_conditions') }}
                        </h1>
                      </div>
                    </div>
                  </v-container>
                </div>
                <v-container>
                  <cgv></cgv>
                </v-container>
              </v-responsive>
            </v-sheet>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.card-field {
  border-bottom: 1px solid #c8c8c8;
}
</style>

<script>
import axios from "axios";
import PageBanner from "@/components/PageBanner";
import Errors from "@/tools/errors";
import Cgv from "@/components/CGV";

export default {
  components: {
    PageBanner,
    Cgv,
  },
  data() {
    return {
      course: false,
      order: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        address: "",
        zip: "",
        locality: "",
        county_id: "",
      },
      stripe: "",
      elements: "",
      card: "",
      intentToken: "",
      addPaymentStatusError: "",
      priceTaxe: 0,
      working: false,
      workingCoupon: false,
      code: "",
      code_verified: "",
      coupon_name: "",
      discound: "",
      taxe: "",
      final_price_taxe: 0,
      final_price_taxe_formatted: "",
      errors: new Errors(),
      showCheckout: true,
      checkboxCGV: false,
      dialog: false,
    };
  },
  computed: {
    courseId() {
      return this.$route.params.courseId;
    },
  },
  mounted() {
    console.log(this.$root.$options.api.stripeApiToken)
    this.getOrder();
    this.getUser();
    this.configureStripe();
    this.loadIntent();
  },
  methods: {
    getUser() {
      axios.get(this.$root.$options.api.url + "/user").then((response) => {
        this.user = response.data.user;
      }).catch(() => {
        localStorage.setItem('courseId', this.$route.params.courseId);
        this.$router.push({
          name: "login",
        });
      });
    },
    getOrder() {
      axios
        .get(this.$root.$options.api.url + "/orders/" + this.courseId)
        .then((response) => {
          this.course = response.data.course;
          this.final_price_taxe = this.course.price_taxe;
          this.final_price_taxe_formatted = this.course.price_taxe_formatted;
          this.taxe = this.course.taxe;

          if ((this.course.can_access || this.course.free ) && this.course.remainingDays > 31) {
            this.$router.push({
              name: "course",
              params: {
                courseId: this.course.id,
              },
            });
          }

          if (this.course.coming_soon) {
            this.$router.push({
              name: "home",
            });
          }
        });
    },
    postOrder() {
      if (this.course.coming_soon) {
        this.$router.push({
          name: "home",
        });
        return;
      }
      axios
        .post(this.$root.$options.api.url + "/orders/store", {
          course_id: this.courseId,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          address: this.user.address,
          zip: this.user.zip,
          locality: this.user.locality,
          county_id: this.user.county_id,
          course: this.course.uuid,
          code: this.code_verified,
          cgv: this.checkboxCGV,
        })
        .then((response) => {
          this.order = response.data.order;

          if (this.showCheckout) {
            this.submitPaymentMethod();
          } else {
            this.unlockCourse();
          }
        })
        .catch((error) => {
          this.errors.record(error.response.data.errors);
        });
    },
    configureStripe() {
      this.stripe = window.Stripe(this.$root.$options.api.stripeApiToken);

      this.elements = this.stripe.elements();
      this.card = this.elements.create("card", {
        hidePostalCode: true,
        style: {
          base: {
            iconColor: "#204f80",
            fontWeight: 500,
            fontFamily: "BodyRegular, Open Sans, Segoe UI, sans-serif",
            fontSize: "20px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
              color: "#204f80",
            },
            "::placeholder": {
              color: "grey",
            },
          },
          invalid: {
            iconColor: "#D50000",
            color: "#B71C1C",
          },
        },
      });

      this.card.mount("#card-element");
    },
    loadIntent() {
      axios
      .get(this.$root.$options.api.url + '/user/setup-intent')
      .then((response) => {
        this.intentToken = response.data;
      });
    },
    coupon() {
      this.workingCoupon = true;
      axios
        .post(this.$root.$options.api.url + "/orders/coupon", {
          course: this.course,
          code: this.code,
        })
        .then((response) => {
          this.workingCoupon = false;
          this.code_verified = response.data.code;
          this.coupon_name = response.data.coupon_name;
          this.discount = response.data.discount_formatted;
          this.taxe = response.data.taxe_formatted;
          this.final_price_taxe = response.data.final_price_taxe;
          this.final_price_taxe_formatted =
            response.data.final_price_taxe_formatted;

          if (this.final_price_taxe == 0) {
            this.showCheckout = false;
          } else {
            this.showCheckout = true;
          }
        })
        .catch((error) => {
          this.workingCoupon = false;
          this.errors.record(error.response.data.errors);
        });
    },
    submitPaymentMethod() {
      this.working = true;
      this.stripe
        .confirmCardSetup(this.intentToken.client_secret, {
          payment_method: {
            card: this.card,
          },
        })
        .then((result) => {
          this.checkout(result.setupIntent.payment_method);
          this.card.clear();
        })
        .catch(() => {
          this.working = false
        });
    },
    checkout(method) {
      axios
        .post(this.$root.$options.api.url + "/orders/checkout", {
          code: this.code,
          payment_method: method,
          order_id: this.order.uuid,
          course: this.course,
        })
        .then(() => {
          this.$router.push({
            name: "home",
          });
        })
        .catch((error) => {
          this.working = false;
          this.errors.record(error.response.data.errors);
        });
    },
    unlockCourse() {
      this.working = true;
      axios
        .post(this.$root.$options.api.url + "/orders/unlock", {
          order_id: this.order.uuid,
          course: this.course,
        })
        .then(() => {
          this.$router.push({
            name: "home",
          });
        })
        .catch((error) => {
          this.working = false;
          this.errors.record(error.response.data.errors);
        });
    },
  },
};
</script>
