<template>
  <div>
    <v-tabs
      class="course-tab tabs-border-bottom mb-5"
      background-color="transparent"
      height="55"
      icons-and-text
      grow
    >
      <v-tab
        link
        :to="{ name: 'course', params: { courseId: courseId, can_access: can_access }}"
        exact
        class="custom-tabs-tab"
      >
        <v-icon class="custom-tabs-icon mb-0" left>mdi-book-open-page-variant-outline</v-icon>
        <span class="custom-tabs-label">{{ $t('courses.nav.modules') }}</span>
      </v-tab>
      <v-tab
        link
        :to="{ name: 'course-reviews', params: { courseId: courseId }}"
        exact
        class="custom-tabs-tab"
      >
        <v-icon  class="custom-tabs-icon mb-0" left>mdi-lead-pencil</v-icon>
        <span class="custom-tabs-label">{{ $t('courses.nav.study_notes') }}</span>
      </v-tab>
      <v-tab
        link
        :to="{ name: 'course-evaluations', params: { courseId: courseId, can_access: can_access }}"
        exact
        class="custom-tabs-tab"
      >
        <v-icon class="custom-tabs-icon mb-0" left>mdi-checkbox-marked-outline</v-icon>
        <span class="custom-tabs-label">{{ $t('courses.nav.tests') }}</span>
      </v-tab>
    </v-tabs>

    <template>
      <v-bottom-navigation
        fixed
        color="primary"
        grow
        class="course-nav"
      >
        <v-btn
          :to="{ name: 'home' }"
          exact
        >
          <span>Cockpit</span>
          <v-icon>mdi-sail-boat</v-icon>
        </v-btn>
        <v-btn
          :to="{ name: 'course', params: { courseId: courseId, can_access }}"
          exact
        >
          <span>Modules</span>
          <v-icon>mdi-book-open-page-variant-outline</v-icon>
        </v-btn>

        <v-btn
          :to="{ name: 'course-reviews', params: { courseId: courseId }}"
          exact
        >
          <span>Révisions</span>
          <v-icon>mdi-lead-pencil</v-icon>
        </v-btn>

        <v-btn
          :to="{ name: 'course-evaluations', params: { courseId: courseId, can_access  }}"
          exact
        >
          <span>Tests</span>
          <v-icon>mdi-checkbox-marked-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </template>

  </div>
</template>

<script>

export default {
  props: [
    'courseId',
    'can_access'
  ],
  data() {
    return {
      value: 'dashboard',
    }
  }
}

</script>
