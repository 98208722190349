/**
 * auth.js
 */
export const SET_USER = 'SET_USER'
export const LOGOUT = 'LOGOUT'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const SET_TOKEN = 'SET_TOKEN'

/**
 * base.js
 */
export const SET_API_URL = 'SET_API_URL'

/**
 * merchant.js
 */
export const SET_MERCHANTS = 'SET_MERCHANTS'
export const FETCH_MERCHANTS_FAILURE = 'FETCH_MERCHANTS_FAILURE'
