<template>
  <v-list>
    <template v-for="(chapter, index) in chapters">
      <v-list-item :key="index" :to="{ name: 'chapter', params: { chapterId: chapter.id }}">
        <v-list-item-content>
          <v-list-item-title>{{ chapter.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider 
        v-if="index + 1 < chapters.length"
        :key="'divider-' + index"
      ></v-divider>
    </template>
  </v-list>
</template>

<script>

export default {
  props: [
    'chapters',
  ] 
}

</script>