<template>
  <div>
    <h2 class=" font-title-2 underlined primary--text mt-16 mb-2">{{ chapter.title }}</h2>
    <template v-for="(content, index) in chapter.contents">
      <content-type :key="'content_' + index" :content="content"></content-type>
    </template>
  </div>
</template>

<script>

import ContentType from '@/components/content/Type'

export default {
  props: [
    'chapter',
  ],
  components: {
    ContentType,
  }
}

</script>
