<template>
  <v-row>
    <v-col cols="12" sm="10" offset-sm="1" style="position: relative">
      <div
        v-if="content.addReview"
        @mouseover="expand = true"
        @mouseleave="expand = false"
        class="chapter-paragraph"
      >
        <v-tooltip v-if="pin" bottom color="secondary" openDelay="500">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-inline-flex mb-2" v-bind="attrs" v-on="on">
              <v-checkbox
                :class="['mt-0', { 'pin-paragraph': !content.isPinned }]"
                hide-details
                v-model="content.isPinned"
                :value="content.active"
                off-icon="mdi-bookmark-plus-outline"
                on-icon="mdi-bookmark-check"
                @click="emitSetReview()"
              ></v-checkbox>
            </div>
          </template>
          <span v-if="!content.isPinned">{{ $t('study_notes.add') }}</span>
          <span v-if="content.isPinned">{{ $t('study_notes.remove') }}</span>
        </v-tooltip>
        <div class="">
          <div
            class="d-flex flex-wrap align-start justify-space-between"
            v-if="content.show_title"
          >
            <h2 class="text-h4 primary--text mb-4">{{ content.title }}</h2>
          </div>
          <div class="mb-4 p-relative" v-html="content.content"></div>

          <v-carousel
            hide-delimiters
            show-arrows-on-hover
            height="auto"
          >
           <v-responsive
              :aspect-ratio="1.778/1"
              >
            <v-carousel-item
              v-for="(image, i) in content.gallery"
              :key="i"
            >
                <v-img :src="image.url" height="100%"></v-img>
            </v-carousel-item>
          </v-responsive>
          </v-carousel>
        </div>
      </div>
      <div v-if="pin && content.addReview" class="hover-border"></div>
      <div v-if="!content.addReview" class="chapter-paragraph">
        <div
          class="d-flex flex-wrap align-start justify-space-between"
          v-if="content.show_title"
        >
          <h2 class="text-h4 primary--text mb-4">{{ content.title }}</h2>
        </div>
        <div class="mb-4 p-relative" v-html="content.content"></div>
       <v-carousel
            hide-delimiters
            show-arrows-on-hover
            height="auto"
          >
           <v-responsive
              :aspect-ratio="1.778/1"
              >
            <v-carousel-item
              v-for="(image, i) in content.gallery"
              :key="i"
            >
                <v-img :src="image.url" height="100%"></v-img>
            </v-carousel-item>
          </v-responsive>
          </v-carousel>
      </div>
    </v-col>
  </v-row>
</template>

<style  lang="scss" scoped>
  .paragraph-iframe {
    display: flex;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
</style>

<script>

export default {
  props: {
    pin: {
      default: true,
      type: Boolean,
    },
    content: {},
  },
  methods: {
    emitSetReview() {
      this.$emit("set-review", {
        uuid: this.$props.content.uuid,
        isPinned: this.$props.content.isPinned,
      });
    },
  },
}

</script>
