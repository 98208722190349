<template>
  <v-container>
    <v-row class="mt-8">
      <v-col cols="12" lg="10" offset-lg="1">
        <course-list :courses="courses"></course-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import CourseList from "@/components/course/List";

export default {
  components: {
    CourseList,
  },
  data() {
    return {
      courses: false,
    };
  },
  mounted() {
    this.getCourses();
  },
  computed: {
    ...mapGetters({
      apiUrl: "base/apiUrl",
    }),
  },
  methods: {
    getCourses() {
      axios.get(this.apiUrl + "/courses").then((response) => {
        this.courses = response.data.courses;
      });
    },
  },
};
</script>
