import axios from 'axios'
import * as types from '../mutation-types'

/**
 * Initial state
 */
const state = {
  user: null,
  token: window.localStorage.getItem('token'),
}

/**
 * Mutations
 */
const mutations = {
  [types.SET_USER](state, { user }) {
    console.log('SET_USER', user)
    state.user = user
  },

  [types.LOGOUT](state) {
    state.user = null
    state.token = null
    window.localStorage.removeItem('token')
  },

  [types.FETCH_USER_FAILURE](state) {
    state.user = null
    window.localStorage.removeItem('token')
  },

  [types.SET_TOKEN](state, { token }) {
    state.token = token
    window.localStorage.setItem('token', token)
  }
}

/**
 * Actions
 */
const actions = {
  saveToken({ commit }, payload) {
    commit(types.SET_TOKEN, payload)
  },

  async fetchUser({ commit, getters, rootGetters }) {
    getters
    try {
      const { data } = await axios.get(rootGetters['base/apiUrl'] + '/user')
      commit(types.SET_USER, data)
    } catch (e) {
      commit(types.FETCH_USER_FAILURE)
    }
  },

  setUser({ commit }, payload) {
    commit(types.SET_USER, payload)
  },

  async logout({ commit, getters, rootGetters }) {
    getters
    await axios.post(rootGetters['base/apiUrl'] + '/logout')
    commit(types.LOGOUT)
  },

  destroy({ commit }) {
    commit(types.LOGOUT)
  }
}

/**
 * Getters
 */
const getters = {
  user: state => state.user,
  check: state => state.user !== null,
  token: state => state.token
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

