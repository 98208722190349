<template>
  <div class="fill-height">
    <div class="tertiary">
      <v-container>
        <v-row class="py-0">
          <v-col cols="12" lg="10" offset-lg="1">
            <page-banner>
              <template slot="subheader">{{ $t('my_account.sub_header') }}</template>
              <template slot="page-title"
                >{{ user.first_name }} {{ user.last_name }}</template
              >
            </page-banner>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container>
      <v-row class="py-0">
        <v-col cols="12" lg="10" offset-lg="1">
         <v-container>
            <v-row>
            <v-col cols="12" offset-sm="1" md="4" offset-md="0">
              <h2 class="text-h5 primary--text mt-4 mb-n6 mt-md-8 mr-md-2">
                {{ $t('my_account.title') }}
              </h2>
            </v-col>
            <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="0">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details="auto"
                    class="mt-8"
                    :label="$t('general.first_name')"
                    v-model="user.first_name"
                    type="text"
                    append-icon="mdi-account-edit-outline"
                    :error-messages="errors.get('first_name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details="auto"
                    class="mt-8"
                    :label="$t('general.last_name')"
                    v-model="user.last_name"
                    type="text"
                    append-icon="mdi-account-edit-outline"
                    :error-messages="errors.get('last_name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details="auto"
                    class="mt-8"
                    :label="$t('general.email_label')"
                    v-model="user.email"
                    type="text"
                    append-icon="mdi-account-edit-outline"
                    :error-messages="errors.get('email')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex">
                  <v-dialog v-model="dialog" width="auto" max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        depressed
                        tile
                        block
                        outlined
                        color="primary"
                        class="mt-6 mt-sm-auto"
                      >
                        {{ $t('my_account.change_password') }}
                      </v-btn>
                    </template>
                    <update-password @close-dialog="closeDialogBtn" />
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-select
                    class="mt-4 mt-sm-8"
                    hide-details="auto"
                    :label="$t('register.interested_by')"
                    v-model="user.type"
                    :items="types"
                    item-text="name"
                    item-value="machineName"
                    :error-messages="errors.get('type')"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    class="mt-4 mt-sm-8"
                    :label="$t('register.source')"
                    v-model="user.discovery"
                    :items="discoveryChannels"
                    item-text="title"
                    item-value="slug"
                    :error-messages="errors.get('discovery')"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="user.newsletter"
                    :label="$t('general.newsletter')"
                    class=""
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-divider class="mt-6 mt-sm-16"></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" offset-sm="1" md="4" offset-md="0">
              <h2 class="text-h5 primary--text mt-4 mb-n6 mt-md-8">{{ $t('general.address') }}</h2>
            </v-col>
            <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="0">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    hide-details="auto"
                    class="mt-8"
                    :label="$t('general.address')"
                    v-model="user.address"
                    type="text"
                    :error-messages="errors.get('address')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" lg="3">
                  <v-text-field
                    hide-details="auto"
                    class="mt-8"
                    :label="$t('general.zipcode')"
                    v-model="user.zip"
                    type="number"
                    :error-messages="errors.get('zip')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" lg="9">
                  <v-text-field
                    hide-details="auto"
                    class="mt-8"
                    :label="$t('general.city')"
                    v-model="user.locality"
                    type="text"
                    :error-messages="errors.get('locality')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    class="mt-8"
                    :label="$t('general.county')"
                    v-model="user.county_id"
                    :items="counties"
                    item-text="county"
                    item-value="id"
                    single-line
                    :error-messages="errors.get('county_id')"
                  ></v-select>
                </v-col>
              </v-row>
              <v-divider class="my-4 my-lg-6"></v-divider>
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn large tile block color="primary" @click="updateUser()">
                    {{ $t('my_account.submit') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
         </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageBanner from "@/components/PageBanner.vue";
import UpdatePassword from "@/components/UpdatePassword.vue";
import axios from "axios";
import Errors from "@/tools/errors";

export default {
  data() {
    return {
      dialog: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        address: "",
        zip: "",
        locality: "",
        county_id: "",
        type: "",
        discovery: "",
        newsletter: false,
      },
      counties: [],
      types: [
        { machineName: 'sailboat', name: this.$i18n.t('register.interested_by.sail_boat') },
        { machineName: 'motorboat', name: this.$i18n.t('register.interested_by.motor_boat') },
        { machineName: 'sailboat_motorboat', name: this.$i18n.t('register.interested_by.both') },
      ],
      discoveryChannels: [
        { slug: 'bateau-ecole', title: this.$i18n.t('register.source.school_boat') },
        { slug: 'facebook', title: this.$i18n.t('register.source.facebook') },
        { slug: 'google', title: this.$i18n.t('register.source.google') },
        { slug: 'bouche-a-oreille', title: this.$i18n.t('register.source.word_of_mouth') },
        { slug: 'autre', title: this.$i18n.t('register.source.other') },
      ],
      errors: new Errors(),
    };
  },
  components: {
    PageBanner,
    UpdatePassword,
  },
  computed: {
    courseId() {
      return this.$route.params.courseId;
    },
  },
  mounted() {
    this.getCounties();
    this.getUser();
  },
  methods: {
    closeDialogBtn(payload) {
      this.dialog = payload.dialog;
    },
    getCounties() {
      axios.get(this.$root.$options.api.url + "/counties").then((response) => {
        this.counties = response.data.counties;
      });
    },
    getUser() {
      axios.get(this.$root.$options.api.url + "/user").then((response) => {
        this.user = response.data.user;
      });
    },
    updateUser() {
      axios
        .put(this.$root.$options.api.url + "/user", {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          address: this.user.address,
          zip: this.user.zip,
          locality: this.user.locality,
          county_id: this.user.county_id,
          type: this.user.type,
          discovery: this.user.discovery,
          newsletter: this.user.newsletter,
        })
        .then((response) => {
          this.user = response.data.user;
          if (this.courseId) {
            this.$router.push({
              name: "course",
              params: {
                courseId: this.courseId,
              },
            });
          } else {
            this.$router.push({
              name: "home",
            });
          }
        })
        .catch((error) => {
          this.errors.record(error.response.data.errors);
        });
    },
  },
};
</script>
