<template>
  <div class="tertiary fill-height">
    <v-container>
      <v-row class="py-0">
        <v-col cols="12" lg="10" offset-lg="1" >
          <page-banner>
            <template v-slot:subheader>{{ $t('courses.sub_header') }}</template>
            <template v-slot:page-title>{{ course.title }}</template>
          </page-banner>
          <course-nav :courseId="courseId" :can_access="course.can_access"></course-nav>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" lg="10" offset-lg="1">
          <module-list :modules="modules"></module-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import axios from 'axios'
import PageBanner from '@/components/PageBanner'
import ModuleList from '@/components/module/List'
import CourseNav from '@/components/course/Nav.vue';

export default {
  components: {
    PageBanner,
    ModuleList,
    CourseNav
  },
  data() {
    return {
      course: false,
      modules: false,
    }
  },
  computed: {
    courseId() {
      return this.$route.params.courseId
    },
    can_access() {
      return this.$route.params.can_access
    }
  },
  mounted() {
    this.getCourse()
  },
  methods: {
    getCourse() {
      axios
        .get(this.$root.$options.api.url + '/courses/' + this.courseId)
        .then(response => {
          this.course = response.data.course
          this.modules = response.data.modules
        })
        .catch(() => {
          this.$router.push({
            name: "home",
          });
        })
    },
  }
}

</script>
