<template>
  <v-card>
    <v-card-title class="text-h5 black--text grey lighten-4">
        <div class="mx-auto">
          <v-icon left color="success" class="mb-1">mdi-lock</v-icon>
          <span>{{ $t('my_account.change_password') }}</span>
        </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="10" offset-sm="1">
            <v-text-field
              class="mt-8"
              :label="$t('password.reset.old_password')"
              v-model="previousPassword"
              :type="showPreviousPassword ? 'text' : 'password'"
              :append-icon="showPreviousPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPreviousPassword = !showPreviousPassword"
              :error-messages="errors.get('password')"
            ></v-text-field>
            <v-text-field
              class="mt-8"
              :label="$t('password.reset.password')"
              v-model="newPassword"
              :type="showNewPassword ? 'text' : 'password'"
              :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showNewPassword = !showNewPassword"
              :error-messages="errors.get('new_password')"
            ></v-text-field>
            <v-text-field
              class="mt-8"
              :label="$t('password.reset.confirm_password')"
              v-model="newPasswordConfirm"
              :type="showPasswordConfirm ? 'text' : 'password'"
              :append-icon="showPasswordConfirm ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPasswordConfirm = !showPasswordConfirm"
              :error-messages="errors.get('new_password_confirmation')"
            ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text large @click="EmitCloseBtn">{{ $t('general.cancel') }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="updatePassword()"
        depressed
        large
        tile
        color="primary"
        class="my-1 mr-2"
        :loading="working"
        >{{ $t('general.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import Errors from "@/tools/errors";

export default {
  name: "update-password",
  data() {
    return {
      previousPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      showPreviousPassword: false,
      showNewPassword: false,
      showPasswordConfirm: false,
      working: false,
      errors: new Errors(),
    };
  },
  methods: {
    EmitCloseBtn() {
      this.$emit('close-dialog', { dialog: false })
    },
    updatePassword() {
      this.working = true;
      axios
        .put(this.$root.$options.api.url + "/user/password", {
          previous_password: this.previousPassword,
          new_password: this.newPassword,
          new_password_confirmation: this.newPasswordConfirm,
        })
        .then(() => {
          this.working = false;
          this.previousPassword = "",
          this.newPassword = "",
          this.newPasswordConfirm = "",
          this.errors = new Errors(),
          this.EmitCloseBtn();
        })
        .catch((error) => {
          this.working = false;
          this.errors.record(error.response.data.errors);
        });
    },
  },
};
</script>
