<template>
  <v-container fluid class="fill-height tertiary py-0">
    <v-row class="fill-height">
      <v-col cols="12" sm="8" class="align-self-sm-center">
        <v-card outlined max-width="550" class="transparent mx-sm-auto my-6 pa-0">
          <v-card-title class="px-0">
            <h2 class="primary--text text-h4">{{ $t('login.title') }}</h2>
          </v-card-title>
          <p class="primary--text text-body-1 mb-0">{{ $t('login.description') }}</p>
          <v-card-text class="px-0">
            <v-form class="">
              <v-text-field
                autocorrect="off"
                autocapitalize="none"
                :label="$t('general.email_label')"
                v-model="email"
                type="email"
                :error-messages="errors.get('email')"
                @input="errors.clear('email')"
                prepend-inner-icon="mdi-at"
              ></v-text-field>
              <v-text-field
                class="mt-8"
                autocorrect="off"
                autocapitalize="none"
                :label="$t('general.password')"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
             <div class="text-right">
                <v-btn
                  text
                  small
                  color="primary"
                  :to="{ name: 'forgot_password' }"
                >{{ $t('login.forgotten_password') }}
            </v-btn>
             </div>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-0">
            <v-btn
              depressed
              large
              block
              color="primary"
              @click="login()"
              :loading="working"
              >{{ $t('login.submit') }}</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-sheet outlined rounded elevation="2" max-width="550" class="white primary--text mx-auto mt-12 pa-3 pa-sm-4">
          <v-icon color="primary" class="mb-2"> mdi-information </v-icon>
          <h2 class="text-h5 mb-4">{{ $t('login.register_box.title') }}</h2>
          <p>{{ $t('login.register_box.description') }}</p>
            <v-btn
              outlined
              large
              block
              class="mr-auto"
              color="primary"
              :to="{ name: 'register' }"
              :loading="working"
              >{{ $t('login.register_box.submit') }}</v-btn
            >
        </v-sheet>
      </v-col>
      <v-col cols="0" sm="4" class="d-none d-sm-block pr-0 py-0">
        <v-img
          height="100%"
          width="100%"
          class=""
          src="@/assets/motorboat.jpg"
        ></v-img>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-sheet scrollable tile>
        <v-img src="@/assets/bg-secondary.svg" style="min-height:100vh" cover>
          <v-responsive>
            <div class="tertiary pt-16 pb-4 pb-sm-6">
              <v-container class="flex-grow-1">
                <div class="d-flex flex-wrap justify-space-between align-end">
                  <div>
                    <v-btn
                      @click.stop="dialog = false"
                      color="secondary"
                      outlined
                    >
                      <v-icon left>mdi-close</v-icon>
                      {{ $t('general.close') }}</v-btn
                    >
                    <h4 class="secondary--text text-h5 mt-8">{{ $t('login.active_session.title') }}</h4>
                  </div>
                </div>
              </v-container>
            </div>
            <v-container>
              <v-card tile max-width="450" class="mx-auto mt-10 mt-lg-16">
                <v-card-text class="py-6 py-lg-8">
                  {{ $t('login.active_session.disconnect.description') }}
                </v-card-text>
                <v-btn
                  depressed
                  large
                  block
                  color="primary"
                  @click="disconnect_tokens()"
                  :loading="working"
                  >{{ $t('login.active_session.disconnect.submit') }}</v-btn
                >
              </v-card>
            </v-container>
          </v-responsive>
        </v-img>
      </v-sheet>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import Errors from "@/tools/errors";

export default {
  data() {
    return {
      email: "",
      password: "",
      errors: new Errors(),
      working: false,
      showPassword: false,
      dialog: false,
    };
  },
  computed: {
    courseId() {
      return this.$route.params.courseId
    },
  },
  methods: {
    login() {
      this.working = true;
      axios
        .post(this.$root.$options.api.url + "/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          if (response.data.error) {
            this.dialog = true
            this.working = false
            return
          }

          this.$root.$options.api.token = response.data.token;
          localStorage.setItem("token", response.data.token);

          this.$root.$options.api.user = response.data.user;
          localStorage.setItem("user", JSON.stringify(response.data.user));

          let courseId = localStorage.getItem('courseId');
          localStorage.removeItem('courseId');

          if (courseId) {
            this.$router.push({
              name: "purchase-course",
              params: {
                courseId: courseId,
              }
            });
          } else {
            this.$router.push({
              name: "home",
            });
          }
        })
        .catch((error) => {
          this.working = false;
          this.errors.record(error.response.data.errors );
        });
    },
    disconnect_tokens() {
      this.working = true;
      axios
        .post(this.$root.$options.api.url + "/disconnect_tokens", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.working = false
          this.login()
        })
    }
  },
};
</script>
