<template>
  <div class="tertiary fill-height">
    <!--
    <loading></loading>
    !-->
    <v-container class="mb-12">
      <v-row class="my-3 mt-lg-12 mb-lg-8">
        <v-col cols="9" offset-lg="1">
          <h1 class="flex-grow-1 font-title primary--text">{{ $t('home.app_title') }}</h1>
        </v-col>
      </v-row>

      <v-row class="my-3 mt-lg-12 mb-lg-8">
        <v-col cols="10" offset-lg="1">
          <v-tabs
            ref="tabs"
            class="d-none d-sm-block"
            v-model="activeTab"
            background-color="transparent"
          >
            <v-tab value="can_access_courses" :disabled="can_access_courses.length === 0 && expired_courses.length === 0">
              {{ $t('courses.tabs.bought') }}
            </v-tab>
            <v-tab
              value="fr"
              :disabled="active_courses['fr'].length === 0 && coming_soon_courses['fr'].length === 0"
            >
              {{ $t('courses.tabs.in_french') }}
            </v-tab>
            <v-tab
              value="en"
              :disabled="active_courses['en'].length === 0 && coming_soon_courses['en'].length === 0"
            >
              {{ $t('courses.tabs.in_english') }}
            </v-tab>
          </v-tabs>
          <v-select
            v-model="activeTab"
            class="d-sm-none d-flex course-selection"
            solo
            :items="[
              {text: $t('courses.tabs.bought'), value: 0, disabled: can_access_courses.length === 0 && expired_courses.length === 0},
              {text: $t('courses.tabs.in_french'), value: 1, disabled: active_courses['fr'].length === 0 && coming_soon_courses['fr'].length === 0},
              {text: $t('courses.tabs.in_english'), value: 2, disabled: active_courses['en'].length === 0 && coming_soon_courses['en'].length === 0},
            ]"
          ></v-select>
        </v-col>
      </v-row>

      <div v-if="tabs[activeTab] === 'can_access_courses'">
        <v-row class="mt-4 sm:mt-10" v-if="can_access_courses.length > 0">
          <v-col cols="12">
            <template v-for="(course, index) in can_access_courses">
              <v-row :key="index">
                <teaser-available :course="course" :can_access="true"></teaser-available>
              </v-row>
            </template>
          </v-col>
        </v-row>

        <v-row class="mt-4 sm:mt-10" v-if="expired_courses.length > 0">
          <v-col cols="12">
            <template v-for="(course, index) in expired_courses">
              <v-row :key="index">
                <teaser-available :course="course" :can_access="false"></teaser-available>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </div>
      <div v-if="tabs[activeTab] === 'fr'">
        <v-row class="mt-4 sm:mt-10 mb-6" v-if="active_courses['fr'].length > 0">
          <v-col cols="12">
            <template v-for="(course, index) in active_courses['fr']">
              <v-row :key="index">
                <teaser-available :course="course" :can_access="false"></teaser-available>
              </v-row>
            </template>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col cols="12">
            <template v-for="(course, index) in coming_soon_courses['fr']">
              <v-row :key="index">
                <teaser-coming-soon :course="course"></teaser-coming-soon>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </div>
      <div v-if="tabs[activeTab] === 'en'">
        <v-row class="mt-4 sm:mt-10" v-if="active_courses['en'].length > 0">
          <v-col cols="12">
            <template v-for="(course, index) in active_courses['en']">
              <v-row :key="index">
                <teaser-available :course="course" :can_access="false"></teaser-available>
              </v-row>
            </template>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col cols="12">
            <template v-for="(course, index) in coming_soon_courses['en']">
              <v-row :key="index">
                <teaser-coming-soon :course="course"></teaser-coming-soon>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </div>

      <v-row class="mt-10" v-if="inactive_courses.length > 0">
        <v-col cols="12">
          <template v-for="(course, index) in inactive_courses">
            <v-row
              :key="index"
              v-if="!course.active"
            >
              <teaser-available :course="course" :can_access="true"></teaser-available>
            </v-row>
          </template>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="10" offset-lg="1">
          <v-sheet color="white" class="px-3 pt-5 mt-12">
            <h2 class="text-h5 primary--text">
              <v-icon class="mr-2" color="primary">mdi-animation-play</v-icon
              >{{ $t('home.videos.title') }}
            </h2>
            <videos></videos>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import TeaserAvailable from "@/components/teasers/Available";
import TeaserComingSoon from "@/components/teasers/ComingSoon";
import Videos from "@/components/video/Videos";
import { availableLocales } from "@/languages";
// import Loading from '@/components/Loading.vue';

export default {
  name: "home",
  data() {
    return {
      can_access_courses: [],
      expired_courses: [],
      active_courses: availableLocales.reduce((acc, availableLanguage) => {
        return {
          ...acc,
          [availableLanguage]: [],
        };
      }, {}),
      coming_soon_courses: availableLocales.reduce((acc, availableLanguage) => {
        return {
          ...acc,
          [availableLanguage]: [],
        };
      }, {}),
      inactive_courses: [],
      demoProgress: 0,
      dialog: false,
      activeTab: 0,
      tabs: ['can_access_courses', 'fr', 'en'],
    };
  },
  components: {
    TeaserAvailable,
    TeaserComingSoon,
    Videos,
    // Loading
  },
  mounted() {
    localStorage.removeItem('courseId');

    this.getCourses();
  },
  methods: {
    getCourses() {
      axios.get(this.$root.$options.api.url + "/courses").then((response) => {

        response.data.courses.forEach(course => {
          if (!course.active) {
            this.inactive_courses.push(course)
          }
          else if (!course.coming_soon && course.active && course.indexed && course.can_access) {
            this.can_access_courses.push(course)
          } else if (!course.coming_soon && course.active && course.indexed && course.is_expired) {
            this.expired_courses.push(course)
          } else if (!course.coming_soon && course.active && course.indexed && !course.can_access) {
            this.active_courses[course.language].push(course)
          } else if (course.coming_soon && course.active && course.indexed) {
            this.coming_soon_courses[course.language].push(course)
          }
        });

        this.activeTab = this.can_access_courses.length > 0 || this.expired_courses.length > 0
            ? 0
            : this.tabs.indexOf(this.$i18n.locale);
      });
    },
    goToCourse() {
      var routeName =
        this.course.free || this.course.can_access
          ? "course"
          : "purchase-course";
      this.$router.push({
        name: routeName,
        params: {
          courseId: this.$props.course.id,
        },
      });
    },
    isAuthenticated() {
      let token = localStorage.getItem("token");
      if (token === null) {
        return false;
      }

      if (token === "undefined") {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="css" scoped>
.teaser-icon {
  width: 2rem;
  height: 2rem;
}
</style>
