<template>
  <v-card flat tile class="transparent mt-6 mt-lg-12 mb-6">
    <p class="secondary--text text-overline mb-0">
      <slot name="subheader"></slot>
    </p>
    <h2 class="font-title-2 sm__font-title-2 primary--text mb-0">
      <slot name="page-title"></slot>
    </h2>
  </v-card>
</template>

<script>
export default {
  name: "page-banner",
};
</script>

