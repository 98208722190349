<template>
  <v-container fluid class="fill-height tertiary py-0">
    <v-row class="fill-height">
      <v-col cols="12" sm="8" class="align-self-sm-center">
        <h1>
          <v-img src="@/assets/logo.svg" width="300" height="200" contain class="d-none d-md-flex mx-auto"></v-img>
        </h1>
        <v-card outlined max-width="550" class="transparent mx-sm-auto my-6 pa-0">
          <v-card-title class="px-0">
            <h2 class="primary--text text-h4">{{ $t('password.forgotten.title') }}</h2>
          </v-card-title>
          <p class="primary--text text-body-1 mb-0">{{ $t('password.forgotten.description') }}</p>
          <v-card-text class="px-0">
            <v-form class="">
              <v-text-field
                :label="$t('general.email_label')"
                v-model="email"
                type="email"
                :error-messages="errors.get('email')"
                @input="errors.clear('email')"
                prepend-inner-icon="mdi-at"
              >
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-0">
            <v-btn
              depressed
              large
              block
              color="primary"
              @click="resetPassword()"
              :loading="working"
              >{{ $t('password.forgotten.submit') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="0" sm="4" class="d-none d-sm-block pr-0 py-0">
        <v-img
          height="100%"
          width="100%"
          class=""
          src="@/assets/motorboat.jpg"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import Errors from "@/tools/errors";

export default {
  data() {
    return {
      email: "",
      errors: new Errors(),
      working: false,
    }
  },
  methods: {
    resetPassword() {
      this.working = true;
      axios
        .post(this.$root.$options.api.url + "/password/email", {
          email: this.email,
        })
        .then((response) => {
          this.$root.$options.api.token = response.data.token;
          this.$root.$options.api.user = response.data.user;

          this.$router.push({
            name: "home",
          });
        })
        .catch((error) => {
          this.working = false;
          this.errors.record(error.response.data.errors);
        });
    }
  }
}
</script>
