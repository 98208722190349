<template>
  <div class="free-mode-end-wrapper">
    <div class="free-mode-end-container text-center">
      <v-container>
        <v-row>
          <v-col cols="12" lg="10" offset-lg="1">
            <p class="font-title-3 mb-12 white--text">
              {{ description }}
            </p>

            <v-btn
              :to="{ name: 'purchase-course', params: { courseId: courseId } }"
              color="primary"
              class="free-mode-end-btn"
              depressed
              x-large
            >
              {{ btnLabel }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  props: ["description", "btnLabel", "courseId"],
};
</script>
