<template>
  <v-row>
    <v-col cols="12" sm="10" offset-sm="1" style="position: relative">
      <div
        v-if="content.addReview"
        @mouseover="expand = true"
        @mouseleave="expand = false"
        class="chapter-paragraph"
      >
        <v-tooltip v-if="pin" bottom color="secondary" openDelay="500">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="d-inline-flex mb-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-checkbox
                :class="['mt-0', { 'pin-paragraph': !content.isPinned }]"
                hide-details
                v-model="content.isPinned"
                :value="content.active"
                off-icon="mdi-bookmark-plus-outline"
                on-icon="mdi-bookmark-check"
                @click="emitSetReview()"
              ></v-checkbox>
            </div>
          </template>
          <span v-if="!content.isPinned">{{ $t('study_notes.add') }}</span>
          <span v-if="content.isPinned">{{ $t('study_notes.remove') }}</span>
        </v-tooltip>
        <div class="chapter-paragraph chapter-framed framed-alert__color">
          <div class="framed-title d-flex align-center">
            <v-icon color="white" class="framed-icon">mdi-alert-octagon-outline</v-icon>
            <div class="text-h5">{{ $t('general.warning') }}</div>
          </div>
          <v-divider class="framed-sepapator"></v-divider>
          <div class="framed-content" v-html="content.content"></div>
        </div>
      </div>
      <div v-if="pin && content.addReview" class="hover-border"></div>
      <div v-if="!content.addReview" class="chapter-paragraph chapter-framed framed-alert__color">
        <div class="framed-title d-flex align-center">
          <v-icon color="white" class="framed-icon">mdi-alert-octagon-outline</v-icon>
          <div class="text-h5">{{ $t('general.warning') }}</div>
        </div>
        <v-divider class="framed-sepapator"></v-divider>
        <div class="framed-content" v-html="content.content"></div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    pin: {
      default: true,
      type: Boolean
    },
    content: {},
  },
  methods: {
    emitSetReview() {
      this.$emit('set-review', { uuid: this.$props.content.uuid, isPinned: this.$props.content.isPinned })
    },
  },
};
</script>
