<template>
  <div>
    <template v-for="(course, index) in courses">
      <v-hover :key="index" v-slot:default="{ hover }">
        <v-card
          link
          :ripple="false"
          :class="['mb-10 course-card', { 'on-hover': hover }]"
          :elevation="hover ? 5 : 0"
          :to="{ name: 'course', params: { courseId: course.id } }"
        >
          <v-img
            src="@/assets/cours-lac-suisse.jpg"
            gradient="to right, rgba(241,249,255,1), rgba(241,249,255,.8), rgba(241,249,255,.1)"
            height="250"
            class="align-center"
          >
            <div class="pl-4 py-4 fill-height">
              <div class="primary--text text-overline">{{ $t('courses.list.title') }}</div>
              <h2
                class="font-title-2 sm__font-title-2 primary--text text-uppercase mb-10"
              >
                {{ course.title }}
              </h2>
              <v-btn class color="primary" depressed x-large
                >{{ $t('courses.list.link') }}</v-btn
              >
            </div>
          </v-img>
    <p>{{course}}</p>

        </v-card>

      </v-hover>

    </template>

  </div>
</template>

<script>
export default {
  props: ["courses"],
};
</script>
