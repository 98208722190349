import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#204f80',
        secondary: '#00adb3',
        tertiary: 'e5f7f7',
        greenbg: '#CDFAD9',
      //  accent: '#8c9eff',
      //  error: '#b71c1c',
      },
    },
  },

});
