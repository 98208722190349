<template>
  <v-dialog v-model="dialog" max-width="900" style="background: white">
    <template v-slot:activator="{ on }">
        <v-card tile v-on="on" class="video-card">
          <v-img
            class="video-thumbnail"
            :src="'//img.youtube.com/vi/' + video + '/maxresdefault.jpg'"
          ></v-img>
         <div class="video-button__wrapper">
            <v-btn
            class="video-button white--text"
            outlined
            large
          >{{ $t('video.start') }}
          </v-btn>
         </div>
        </v-card>
    </template>

    <v-card tile class="pb-4">
      <v-responsive class="pa-3" :aspect-ratio="16 / 9">
        <iframe
          v-if="dialog"
          width="100%"
          height="100%"
          :src="'//www.youtube.com/embed/' + video"
          allowfullscreen
          frameborder="0"
        ></iframe>
      </v-responsive>
      <div class="primary--text mb-0 pb-4 px-3 text-h6">
        {{ content.title }}
      </div>
      <p class="px-3 mb-0" v-html="content.content"></p>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.video-card {
  cursor: pointer;

&:hover .video-thumbnail:after {
    opacity: .8;
  }
   &:hover .video-button {
    opacity: 1;
  }
}

.video-thumbnail:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  background-color: #204f80;
  opacity: 0;
}

.video-button__wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-button {
  opacity: 0;
}

</style>

<script>
export default {
  props: ["content"],
  data() {
    return {
      dialog: false,
      video: this.$props.content.youtube_link,
    };
  },
};
</script>
