<template>
  <div class="">
    <content-text
      v-if="content.type == 'text'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-text>
    <content-text-image
      v-if="content.type == 'text_image'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-text-image>
     <content-text-left-image-right
      v-if="content.type == 'text_left_image_right'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-text-left-image-right>
     <content-text-right-image-left
      v-if="content.type == 'image_left_text_right'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-text-right-image-left>
    <content-text-image-small
      v-if="content.type == 'image_small'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-text-image-small>
    <content-text-image-medium
      v-if="content.type == 'image_medium'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-text-image-medium>
     <content-text-image
      v-if="content.type == 'image_large'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-text-image>
    <content-attention
      v-if="content.type == 'attention'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-attention>
    <content-information
      v-if="content.type == 'information'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-information>
    <content-definition
      v-if="content.type == 'definition'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-definition>
    <content-laws
      v-if="content.type == 'laws'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-laws>
    <content-abstract
      v-if="content.type == 'common_error'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-abstract>
    <content-video
      v-if="content.type == 'video'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-video>
    <content-iframe
      v-if="content.type == 'gallery'"
      :content="content"
      :pin="pin"
      @set-review="setReview"
    ></content-iframe>
  </div>
</template>

<script>

import axios from "axios";
import ContentText from '@/components/content/Text'
import ContentTextImage from '@/components/content/TextImage'
import contentTextLeftImageRight from '@/components/content/TextLimageR'
import contentTextRightImageLeft from '@/components/content/TextRimageL'
import ContentTextImageSmall from '@/components/content/TextImageSmall'
import ContentTextImageMedium from '@/components/content/TextImageMedium'
import ContentLaws from '@/components/content/Laws'
import ContentAttention from '@/components/content/Attention'
import ContentDefinition from '@/components/content/Definition'
import ContentAbstract from '@/components/content/Abstract'
import ContentInformation from '@/components/content/Information'
import ContentVideo from '@/components/content/Video'
import ContentIframe from '@/components/content/Iframe'

export default {
  props: [
    'content', 
    'pin'
  ],
  components: {
    ContentText,
    ContentTextImage,
    contentTextLeftImageRight,
    contentTextRightImageLeft,
    ContentTextImageSmall,
    ContentTextImageMedium,
    ContentLaws,
    ContentAttention,
    ContentDefinition,
    ContentAbstract,
    ContentInformation,
    ContentVideo,
    ContentIframe,
  },
  methods: {
    setReview(contentPinned) {
      axios
        .post(this.$root.$options.api.url + "/contents/tag", {
          uuid: contentPinned.uuid,
          pinned: contentPinned.isPinned,
        });
    }
  }
}

</script>
