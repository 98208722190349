import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store/index'
import './registerServiceWorker'
import VueGtag from "vue-gtag";
import './sass/app.scss'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { locales, defaultLocale } from './languages'

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: "https://39bf92335dd44190b7122fe71bd12abe@errors.devfactory.ch/16",
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

// Add a request interceptor
axios
.interceptors
.request
.use(function (config) {
  const token = localStorage.getItem('token')
  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

Vue.use(VueGtag, {
  config: { id: "G-JTTHHB78T1" }
});

Vue.use(VueI18n);

var i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages: locales,
})

let current = new Vue({
  vuetify,
  router,
  i18n,
  store,
  api: {
    url: process.env.VUE_APP_API_BASE_URL,
    token: localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')),
    stripeApiToken: process.env.VUE_APP_STRIPE_KEY,
  },
  created() {
    this.$store.dispatch('base/setApiUrl', process.env.VUE_APP_API_BASE_URL)
  },
  render: h => h(App)
})

router.onReady(() => {
  current.$mount('#app')
})

router.beforeEach((to, from, next) => {
  let openRoutes = [
    'home',
    'login',
    'register',
    'reset_password',
    'forgot_password',
  ]

  if (current.$route.name != 'evaluation') {
    for (var i = 0; i < localStorage.length; i++) {
      var key = localStorage.key(i);

      if (key.slice(0,6) === "timer_") {
        localStorage.removeItem(key);
      }
    }
  }

  if (!openRoutes.includes(to.name) && !isAuthenticated()) {
    next({ name: 'login' })
  }
  else {
    next()
  }
})

function isAuthenticated() {
  let token = localStorage.getItem('token')
  if (token === null) {
    return false
  }

  if (token === 'undefined') {
    return false
  }

  axios
    .post(current.$root.$options.api.url + "/check-connected-token")
    .then(response => {
      if (! response.data.isConnected) {
        current.$options.api.token = null;
        localStorage.removeItem("token");
        return false
      }
    })

  return true
}


// Add a response interceptor
axios
.interceptors
.response
.use(function (response) {
  return response;
},
function (error) {
  if (error.response.status === 401) {
    current.$options.api.token = null
    localStorage.removeItem('token')
    current
    .$router
    .push({
      name: 'login'
    })
  }
  else {
    return Promise.reject(error);
  }
});
