<template>
  <v-container>
    <v-row>
      <template v-for="(content, index) in contents">
        <v-col :key="index" cols="12" sm="4" lg="3">
          <video-modal :content="content"></video-modal>
          <p class="text-primary mb-0 mt-1">{{ content.title }}</p>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Errors from "@/tools/errors";

import VideoModal from "@/components/video/Modal";

export default {
  data() {
    return {
      contents: false,
      errors: new Errors(),
    };
  },
  mounted() {
    this.getVideos();
  },
  components: {
    VideoModal,
  },
  methods: {
    getVideos() {
      axios
        .get(this.$root.$options.api.url + "/contents/videos")
        .then((response) => {
          this.contents = response.data.contents;
        })
        .catch((error) => {
          this.errors.record(error.response.data.errors);
        });
    },
  },
};
</script>
