<template>
  <v-app>
    <v-sheet
      v-if="deferredPrompt"
      color="primary"
      rounded
      dark
      class="text-left ma-1 px-1 pb-1 pt-3 elevation-1"
    >
      <div>
        <div class="d-flex mb-3">
          <img src="@/assets/logo-icon-white.svg" contain class="mr-3" height="40" />
          <div class="text-h6">{{ $t('app.prompt.description') }}</div>
        </div>
      </div>
      <v-divider></v-divider>
        <div class="text-right">
          <v-btn text @click="dismiss()">{{ $t('general.close') }}</v-btn>
        <v-btn text @click="install()">{{ $t('app.prompt.install') }}</v-btn>
        </div>
    </v-sheet>
    <v-navigation-drawer
      class="d-md-none blue lighten-5"
      v-model="drawer"
      temporary
      app
    >
      <v-list dense>
        <v-list-item link exact :to="{ name: 'home' }">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('general.cockpit') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="userLoggedIn()" link :to="{ name: 'user' }">
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('general.my_account') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="userLoggedIn()" link @click="logout()">
          <v-list-item-action>
            <v-icon color="error darken-4">mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="error--text text--darken-4"
              >{{ $t('general.disconnect') }}
              </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!userLoggedIn()" link :to="{ name: 'login' }">
          <v-list-item-action>
            <v-icon color="success darken-4">mdi-login</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="success--text text--darken-4"
              >{{ $t('general.connect') }}
              </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-chip-group v-model="currentLocale" class="language-switcher ml-0 px-5">
        <v-chip v-for="locale in locales" :key="locale" :value="locale" class="text-uppercase">
          {{ locale }}
        </v-chip>
      </v-chip-group>
    </v-navigation-drawer>
    <header style="position: relative">

      <v-container
        class="d-none d-md-flex align-center pb-2 pt-4 pt-lg-10"
      >
       <v-row>
        <v-col cols="12" lg="10" offset-lg="1" class="d-flex align-center">
        <a :href="homeUrl">
          <img src="@/assets/logo.svg" height="58" alt=""/>
        </a>
        <v-spacer></v-spacer>
        <v-btn
          text
          :to="{ name: 'home' }"
          x-large
          tile
          color="secondary"
          class="text-body-medium mr-2"
          >{{ $t('general.cockpit') }}
          </v-btn>
        <div v-if="userLoggedIn()" class="nav-btn-group">
          <v-btn-toggle tile>
            <v-btn tile color="secondary" depressed :to="{ name: 'user' }">
              <v-icon left color="white">mdi-account</v-icon>
              <span class="white--text">{{ $t('general.my_account') }}</span>
            </v-btn>
            <v-btn
              class="secondary nav-btn-group--expand-icon"
              icon
              depressed
              @click="expand = !expand"
            >
              <v-icon color="white">{{
                expand ? "mdi-menu-up" : "mdi-menu-down"
              }}</v-icon>
            </v-btn>
          </v-btn-toggle>
          <v-expand-transition>
            <v-card v-show="expand" class="nav-btn-group--expand mx-auto mt-2">
              <v-btn
                text
                class="mt-2 mx-3 mb-1"
                @click="
                  logout();
                  expand = !expand;
                "
                >{{ $t('general.disconnect') }}</v-btn
              >
            </v-card>
          </v-expand-transition>
        </div>
        <div v-if="!userLoggedIn()" class="nav-btn-group">
          <v-btn
            text
            :to="{ name: 'login' }"
            x-large
            tile
            color="secondary"
            class="text-body-medium mr-2"
            >{{ $t('general.connect') }}</v-btn
          >
        </div>
          <v-chip-group v-model="currentLocale" class="language-switcher">
            <v-chip v-for="locale in locales" :key="locale" :value="locale" class="text-uppercase">
              {{ locale }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      </v-container>
      <div class="d-none d-md-flex header-wave"></div>

    </header>

    <v-app-bar class="d-md-none flex-grow-0 white" style="position: static" app flat>
      <v-app-bar-nav-icon
        style="position:absolute"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="flex-grow-1 px-0">
         <a :href="homeUrl">
            <v-img
            class="mx-auto"
            src="@/assets/logo.svg"
            contain
            alt="logo"
            width="150"
            height="50"
          ></v-img>
         </a>
      </v-toolbar-title>
      <div class="header-wave"></div>
    </v-app-bar>

    <v-main class="pt-0">
      <router-view></router-view>
    </v-main>
    <v-footer color="primary">
      <span class="white--text">&copy; {{ $t('general.copyright_light', { currentYear }) }}</span>
    </v-footer>
  </v-app>
</template>

<style lang="scss"  >
.nav-btn-group {
  position: relative;
}

.nav-btn-group--expand {
  position: absolute !important;
  z-index: 2;
  background-color: #f1f9ff;
}

.nav-btn-group--expand-icon {
  background-color: #00adb3;
}
</style>

<script>

import moment from "moment";
import Cookies from "js-cookie";
import axios from 'axios';
import { availableLocales, defaultLocale } from "@/languages";

export default {
  name: "App",
  data() {
    return {
      deferredPrompt: null,
      drawer: false,
      expand: false,
      pages: {
        "Cockpit": "home",
      },
      homeUrl: false,
      locales: availableLocales,
      currentLocale: defaultLocale,
    };
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
    /*let openRoutes = [
      'login',
      'register',
      'reset_password',
      'forgot_password',
    ]*/
    /*if (!this.userLoggedIn()) {
      if (this.$route.name != "login") {
        this.$router.push({
          name: "login",
        });
      }
    }*/
  },
  beforeMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const queryLocale = urlParams.get('locale');
    const savedLocale = window.localStorage.getItem('locale');

    if (queryLocale && this.locales.includes(queryLocale)) {
      window.localStorage.setItem("locale", queryLocale);

      this.currentLocale = queryLocale;
    } else {
      if (savedLocale) {
        this.currentLocale = savedLocale;
      }
    }
  },
  watch: {
    currentLocale(newLocale) {
      window.localStorage.setItem("locale", newLocale);
      this.$i18n.locale = newLocale;
      window.dispatchEvent(new Event("resize"));
    }
  },
  computed: {
    currentYear() {
      return moment().format("YYYY");
    },
  },
  created() {
    this.homeUrl = process.env.VUE_APP_SITE_URL;
  },
  methods: {
    async dismiss() {
      Cookies.set("add-to-home-screen", null, { expires: 15 });
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    userLoggedIn() {
      if (this.$root.$options.api.token === null) {
        return false;
      }

      if (this.$root.$options.api.token === "undefined") {
        return false;
      }

      return true;
    },
    logout() {
      axios
        .post(this.$root.$options.api.url + "/logout")
        .then(() => {
          this.$root.$options.api.token = null;

          localStorage.removeItem("token");
          this.$router.push({
            name: "login",
          });
        })
    },
  },
};
</script>
