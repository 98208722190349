<template>
  <div style="position: relative">
    <div
      @mouseover="expand = true"
      @mouseleave="expand = false"
      class="chapter-paragraph"
    >
      <v-tooltip bottom color="secondary" openDelay="500">
        <template v-slot:activator="{ on, attrs }">
          <div
            class="d-inline-flex mb-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-checkbox
              :class="['mt-0',{ 'pin-paragraph': !question.isPinned }]"
              hide-details
              v-model="question.isPinned"
              :value="question.active"
              off-icon="mdi-bookmark-plus-outline"
              on-icon="mdi-bookmark-check"
              @click="emitSetReview()"
            ></v-checkbox>
          </div>
        </template>
        <span v-if="!question.isPinned">{{ $t('study_notes.add') }}</span>
        <span v-if="question.isPinned">{{ $t('study_notes.remove') }}</span>
      </v-tooltip>
      <v-card max-width="100%" elevation="3" class="mx-auto pb-3">
        <template v-for="(image, index) in question.images">
          <v-img :key="index" :src="image.url" :aspect-ratio="16/9"></v-img>
        </template>
        <v-list-item class="py-3 mb-3 primary">
          <v-list-item-content class="text-h5 white--text">{{ question.question }}</v-list-item-content>
        </v-list-item>
        <template v-for="(answer, index) in question.answers">
          <div :key="'answer_' + index">
          <answer :answer="answer" :show-result="showResult">
          </answer>
          </div>
        </template>
      </v-card>
    </div>
    <div v-if="!question.is_correct" class="mt-10">
      <div v-if="question && question.contents.length >= 1" class="text-h5 mb-2">{{ $t('question.meaning') }}</div>
      <template v-for="(content, index) in question.contents">
        <content-type
          :key="index"
          :content=transformContentType(content)
          :pin="false"
        ></content-type>
      </template>
    </div>
  </div>
</template>

<script>

import Answer from '@/components/question/Answer'
import ContentType from "@/components/content/Type";

export default {
  props: [
    'question',
    'showResult',
  ],
  components: {
    Answer,
    ContentType,
  },
  methods: {
    transformContentType(content) {
      let types = [
        'definition',
        'information',
        'attention',
        'laws'
      ]

      if (types.includes(content.type)) {
        content.type = 'text'
      }

      return content
    },
    emitSetReview() {
      this.$emit("set-review", {
        uuid: this.$props.question.id,
        isPinned: this.$props.question.isPinned,
      });
    },
  }
}

</script>
