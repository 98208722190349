<template>
<v-sheet class="tertiary fill-height">
<v-container>
  <v-row>
    <v-col cols="12">
      <v-card max-width="800" class="mx-auto mt-sm-8 mt-lg-16">
    <v-card-title class="text-h5 black--text grey lighten-4">
        <div class="d-flex flex-wrap mx-auto">
          <v-icon left color="success" class="mb-1">mdi-lock</v-icon>
          <div class="fix-word-break">{{ $t('password.reset.title') }}</div>
        </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="10" offset-sm="1">
            <v-text-field
              :label="$t('general.email_label')"
              v-model="email"
              type="email"
              :error-messages="errors.get('email')"
              @input="errors.clear('email')"
              prepend-inner-icon="mdi-at"
             ></v-text-field>
            <v-text-field
              class="mt-8"
              :label="$t('password.reset.password')"
              v-model="newPassword"
              :type="showNewPassword ? 'text' : 'password'"
              :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showNewPassword = !showNewPassword"
              :error-messages="errors.get('password')"
            ></v-text-field>
            <v-text-field
              class="mt-8"
              :label="$t('password.reset.confirm_password')"
              v-model="newPasswordConfirm"
              :type="showPasswordConfirm ? 'text' : 'password'"
              :append-icon="showPasswordConfirm ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPasswordConfirm = !showPasswordConfirm"
              :error-messages="errors.get('password_confirmation')"
            ></v-text-field>
             <v-btn
              @click="updatePassword()"
              depressed
              x-large
              color="primary"
              class="mt-3"
              :loading="working"
              >{{ $t('password.reset.submit') }}
            </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
    </v-col>
  </v-row>
  </v-container>
  </v-sheet>
</template>

<script>
import axios from "axios";
import Errors from "@/tools/errors";

export default {
  data() {
    return {
      email: "",
      newPassword: "",
      newPasswordConfirm: "",
      showNewPassword: false,
      showPasswordConfirm: false,
      working: false,
      errors: new Errors(),
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
  },
  methods: {
    updatePassword() {
      this.working = true;
      axios
        .post(this.$root.$options.api.url + "/password/reset", {
          token: this.token,
          email: this.email,
          password: this.newPassword,
          password_confirmation: this.newPasswordConfirm,
        })
        .then(() => {
          this.working = false;
          this.newPassword = "";
          this.newPasswordConfirm = "";
          this.errors = new Errors();

          this.$router.push({
            name: "login",
          });
        })
        .catch((error) => {
          this.working = false;
          this.errors.record(error.response.data.errors);
        });
    },
  },
};
</script>
