var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.evaluations),function(evaluation,index){return [_c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:['mb-10', { 'on-hover': hover }],attrs:{"link":"","ripple":false,"elevation":hover ? 5 : 2,"to":_vm.getEvaluationLink(evaluation.id)}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('div',{staticClass:"px-4 py-4 fill-height"},[_c('h2',{staticClass:"text-h5 primary--text mb-6"},[_vm._v(" "+_vm._s(evaluation.evaluation)+" ")]),_c('v-btn',{attrs:{"tile":"","depressed":"","small":"","color":"primary"}},[_vm._v(_vm._s(_vm.$t('evaluations.start'))+" ")])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[(evaluation.best_score)?_c('div',{staticClass:"mx-2 mx-4 py-4 fill-height text-center best-score"},[_c('h3',{staticClass:"text-h6 primary--text mb-2"},[_vm._v(" "+_vm._s(_vm.$t('evaluations.score_title'))+" ")]),_c('div',[_c('p',{class:[
                    'text-h3 mb-2',
                    {
                      'green--text': evaluation.best_score_scale === 'good',
                      'orange--text': evaluation.best_score_scale === 'medium',
                      'red--text': evaluation.best_score_scale === 'bad',
                    }
                ]},[_vm._v(_vm._s(evaluation.best_score)+"%")]),_c('p',{staticClass:"small grey--text mb-0"},[_vm._v("("+_vm._s(_vm.$t('evaluations.test_from'))+" "+_vm._s(evaluation.best_score_date)+")")])])]):_vm._e()])],1)],1)]}}],null,true)})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }