<template>
  <v-container fluid class="fill-height py-0 tertiary">
    <v-row class="fill-height">
      <v-col cols="12" sm="8" class="align-self-center">
        <v-card
          outlined
          max-width="600"
          class="transparent mx-sm-auto my-6 pa-0"
        >
          <v-card-title class="px-0">
            <h1 class="primary--text text-h4">{{ $t('register.title') }}</h1>
          </v-card-title>
          <p class="primary--text text-body-1 mb-0">
            {{ $t('register.description') }}
          </p>
          <v-card-text class="px-0">
            <v-form class="">
              <v-row dense class="mb-10">
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('general.first_name')"
                    v-model="firstName"
                    type="text"
                    :error-messages="errors.get('first_name')"
                    @input="errors.clear('first_name')"
                    prepend-inner-icon="mdi-account-circle"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('general.last_name')"
                    v-model="lastName"
                    type="text"
                    :error-messages="errors.get('last_name')"
                    @input="errors.clear('last_name')"
                    prepend-inner-icon="mdi-account-circle"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    autocorrect="off"
                    autocapitalize="none"
                    :label="$t('general.email_label')"
                    v-model="email"
                    type="email"
                    :error-messages="errors.get('email')"
                    @input="errors.clear('email')"
                    prepend-inner-icon="mdi-at"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    autocorrect="off"
                    autocapitalize="none"
                    :label="$t('general.password')"
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="showPassword = !showPassword"
                    :error-messages="errors.get('password')"
                    @input="errors.clear('password')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="newsletter"
                    :label="$t('general.newsletter')"
                    class=""
                  ></v-checkbox>
                </v-col>
              </v-row>
              <h2 class="text-h5 primary--text">{{ $t('register.details') }}</h2>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('general.address')"
                    v-model="address"
                    type="text"
                    :error-messages="errors.get('address')"
                    @input="errors.clear('address')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    :label="$t('general.zipcode')"
                    v-model="npa"
                    type="text"
                    :error-messages="errors.get('npa')"
                    @input="errors.clear('npa')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    :label="$t('general.city')"
                    v-model="locality"
                    type="text"
                    :error-messages="errors.get('locality')"
                    @input="errors.clear('locality')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('general.county')"
                    v-model="county"
                    :items="counties"
                    item-text="county"
                    item-value="id"
                    single-line
                    :error-messages="errors.get('county')"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('register.interested_by')"
                    v-model="type"
                    :items="types"
                    item-text="name"
                    item-value="machineName"
                    single-line
                    :error-messages="errors.get('type')"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('register.source')"
                    v-model="discovery"
                    :items="discoveryChannels"
                    item-text="title"
                    item-value="slug"
                    single-line
                    :error-messages="errors.get('discovery')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-0">
            <v-btn
              depressed
              large
              width="50%"
              color="primary"
              @click="login()"
              :loading="working"
              >{{ $t('register.submit') }}</v-btn
            >
            <v-btn
              depressed
              large
              text
              width="50%"
              color="primary"
              :to="{ name: 'login' }"
              :loading="working"
              >{{ $t('register.login') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="0" sm="4" class="d-none d-sm-block pr-0 py-0">
        <v-img
          height="100%"
          width="100%"
          class
          src="@/assets/motorboat.jpg"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Errors from "@/tools/errors";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      newsletter: true,
      address: "",
      npa: "",
      locality: "",
      county: "",
      counties: [],
      types: [
        { machineName: 'sailboat', name: this.$i18n.t('register.interested_by.sail_boat') },
        { machineName: 'motorboat', name: this.$i18n.t('register.interested_by.motor_boat') },
        { machineName: 'sailboat_motorboat', name: this.$i18n.t('register.interested_by.both') },
      ],
      discoveryChannels: [
        { slug: 'bateau-ecole', title: this.$i18n.t('register.source.school_boat') },
        { slug: 'facebook', title: this.$i18n.t('register.source.facebook') },
        { slug: 'google', title: this.$i18n.t('register.source.google') },
        { slug: 'bouche-a-oreille', title: this.$i18n.t('register.source.word_of_mouth') },
        { slug: 'autre', title: this.$i18n.t('register.source.other') },
      ],
      showPassword: false,
      errors: new Errors(),
      working: false,
    };
  },
  computed: {
    ...mapGetters({
      apiUrl: "base/apiUrl",
    }),
  },
  mounted() {
    this.getCounties();
  },
  methods: {
    getCounties() {
      axios.get(this.apiUrl + "/counties").then((response) => {
        this.counties = response.data.counties;
        this.counties.sort((a, b) => {
          if (a.county < b.county) {
            return -1;
          }
          if (a.county > b.county) {
            return 1;
          }
          return 0;
        });
      });
    },
    login() {
      this.working = true;
      axios
        .post(this.$root.$options.api.url + "/register", {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.password,
          address: this.address,
          npa: this.npa,
          locality: this.locality,
          county: this.county,
          type: this.type,
          discovery: this.discovery,
          newsletter: this.newsletter,
        })
        .then((response) => {
          this.$root.$options.api.token = response.data.token;
          localStorage.setItem("token", response.data.token);

          this.$root.$options.api.user = response.data.user;
          localStorage.setItem("user", JSON.stringify(response.data.user));

          let courseId = localStorage.getItem('courseId');
          localStorage.removeItem('courseId');

          if (courseId) {
            this.$router.push({
              name: "purchase-course",
              params: {
                courseId: courseId,
              }
            });
          } else {
            this.$router.push({
              name: "home",
            });
          }
        })
        .catch((error) => {
          this.working = false;
          this.errors.record(error.response.data.errors);
        });
    },
  },
};
</script>
