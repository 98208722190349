<template>
  <div class="fill-height tertiary">
    <div >
      <v-container class="tabs-border-bottom">
        <v-row>
          <v-col cols="12" lg="10" offset-lg="1" class="pb-0">
            <v-card flat tile class="transparent mt-6 mt-lg-8">
              <v-btn
                :to="{
                  name: 'course-evaluations',
                  params: { courseId: course.id, can_access: course.can_access },
                }"
                outlined
                small
                color="secondary"
                class="mb-6 mb-lg-10"
              >
                <v-icon left> mdi-arrow-left</v-icon>
                {{ $t('evaluations.title') }}
              </v-btn>
              <div class="d-flex flex-wrap justify-space-between align-center">
                <h2 class="flex-grow-1 font-title-2 sm__font-title-2 primary--text my-2">
                  {{ evaluation.evaluation }}
                </h2>

                  <v-chip class="my-2" color="grey lighten-5" label>
                    <span class="pr-1">{{ $t('evaluations.time_left') }}</span>
                    <span class="tabular-nums">{{ minutes }}:{{ seconds }}</span>
                  </v-chip>
              </div>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="py-0">
      <v-row>
        <v-col lg="8" offset-lg="2">
          <p class="text-overline text-center mt-6">{{ $t('evaluations.questions') }}</p>
          <v-pagination
            v-model="page"
            :length="questions.length"
            class="mb-8"
            :total-visible="10"
            circle
          ></v-pagination>
          <question
            class="mb-2"
            :question="currentQuestion"
            :showResult="isValidated"
            @set-review="setReview"
          ></question>
          <div v-if="evaluation.exam" class="mb-6"></div>
          <v-dialog v-model="dialog" width="auto" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="isComplete"
                v-on="on"
                v-bind="attrs"
                depressed
                large
                color="secondary"
                class="mb-4"
              >
                Résultats
              </v-btn>
            </template>
            <result
              :goodAnswers="goodAnswers"
              :badAnswers="badAnswers"
              :goodQuestions="goodQuestions"
              :badQuestions="badQuestions"
              :maxErrors="maxErrors"
              :success="success"
              :questions="questions"
              class="mt-10 mb-10"
              @showQuestion="showQuestion"
              @close-dialog="closeDialogBtn"
            />
          </v-dialog>
          <v-btn
            v-if="!isComplete"
            color="secondary"
            class="mb-12"
            elevation="8"
            x-large
            block
            @click="validateQuestion"
          >
            {{ validationBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid lightgrey;
}
</style>

<script>
import axios from "axios";
import Question from "@/components/question/Question";
import Result from "@/components/evaluation/Result";

export default {
  data() {
    return {
      evaluation: false,
      questions: false,
      current: false,
      page: 1,
      badAnswers: 0,
      goodAnswers: 0,
      badQuestions: 0,
      goodQuestions: 0,
      course: false,
      isComplete: false,
      success: false,
      showResult: false,
      isValidated: false,
      validationBtn: "",
      maxErrors: 0,
      dialog: false,
      minutes: 0,
      seconds: 0,
    };
  },
  components: {
    Question,
    Result,
  },
  watch: {
    page: function () {
      let current = this.getCurrentQuestion();
      if (current.validated) {
        this.isValidated = true;
      } else {
        this.isValidated = false;
      }

      if (this.evaluation.exam) {
        if (this.page == this.questions.length) {
          this.validationBtn = this.$i18n.t('evaluations.end');
        } else {
          this.validationBtn = this.$i18n.t('evaluations.next');
        }
      }
    },
  },
  computed: {
    currentQuestion() {
      if (this.questions) {
        return this.questions.find((question, index) => {
          return index == this.page - 1;
        });
      }
      return false;
    },
    evaluationId() {
      return this.$route.params.evaluationId;
    },
    courseId() {
      return this.$route.params.courseId;
    },
  },
  mounted() {
    this.getEvaluations();
    this.getCourse();
  },
  methods: {
    validateQuestion() {
      if (this.evaluation.exam) {
        if (this.page != this.questions.length) {
          this.nextQuestion();
        } else {
          this.postResultExam();
          this.isComplete = true;
          this.dialog = true;
        }
      } else {
        if (this.isValidated) {
          this.validationBtn =  this.$i18n.t('evaluations.validate');
          this.nextQuestion();
        } else {
          if (this.page == this.questions.length) {
            this.postResultExam();
            this.isComplete = true;
          } else {
            this.postResultQuestion();
          }
        }
      }
    },
    getEvaluations() {
      axios
        .get(this.$root.$options.api.url + "/evaluations/" + this.evaluationId + "/course/" + this.courseId)
        .then((response) => {
          this.evaluation = response.data.evaluation;
          this.questions = response.data.questions;
          this.userAnswers = response.data.answers;

          if (this.evaluation.exam) {
            this.minutes = this.evaluation.minutes - 1;
            this.seconds = 59;

            let timer = JSON.parse(localStorage.getItem("timer_" + this.evaluationId));

            if (timer) {
              this.minutes = timer.minutes;
              this.seconds = timer.seconds;
            }
          }

          if (this.evaluation.exam) {
            this.countDownTimer();
          }

          this.validationBtn = this.evaluation.exam
            ? this.$i18n.t('evaluations.next')
            : this.$i18n.t('evaluations.validate');
        });
    },
    getCourse() {
      axios
        .get(this.$root.$options.api.url + "/courses/" + this.courseId)
        .then((response) => {
          this.course = response.data.course;
        });
    },
    getCurrentQuestion() {
      if (this.questions) {
        return this.questions.find((question, index) => {
          return index == this.page - 1;
        });
      }
      return false;
    },
    postResultQuestion() {
      axios
        .post(this.$root.$options.api.url + "/evaluations/result-question", {
          evaluation_id: this.evaluationId,
          questions: this.questions,
          current: this.getCurrentQuestion(),
          good_answers: this.good_answers,
          bad_answers: this.bad_answers,
          current_question: this.page,
        })
        .then((response) => {
          this.questions = response.data.questions;
          this.badAnswers = response.data.bad_answers;
          this.goodAnswers = response.data.good_answers;
          this.isComplete = response.data.is_finished;
          this.success = response.data.success;
          this.maxErrors = response.data.max_errors;
          this.isValidated = true;
          this.showResult = true;
          this.validationBtn =
            this.page == this.questions.length
              ? this.$i18n.t('evaluations.end')
              : this.$i18n.t('evaluations.next');
        });
    },
    postResultExam() {
      axios
        .post(this.$root.$options.api.url + "/evaluations/result-exam", {
          evaluation_id: this.evaluationId,
          questions: this.questions,
        })
        .then((response) => {
          this.questions = response.data.questions;
          this.goodAnswers = response.data.good_answers;
          this.badAnswers = response.data.bad_answers;
          this.goodQuestions = response.data.good_questions;
          this.badQuestions = response.data.bad_questions;
          this.isComplete = response.data.is_finished;
          this.success = response.data.success;
          this.maxErrors = response.data.max_errors;
          this.isValidated = true;
          this.showResult = true;

          this.postReview();
          this.removeTimerLocalStorage();
        });
    },
    nextQuestion() {
      if (this.page < this.questions.length) {
        this.page++;
      }
    },
    showQuestion(index) {
      this.page = index + 1;
    },
    closeDialogBtn(payload) {
      this.dialog = payload.dialog;
    },
    postReview() {
      axios
        .post(this.$root.$options.api.url + "/questions/tags", {
          bad_answers: this.badAnswers,
          evaluation_id: this.evaluationId,
          course_id: this.courseId,
          questions: this.questions,
        })
        .then((response) => {
          console.log(response);
        });
    },
    countDownTimer() {
      if (!this.evaluationId) {
        this.removeTimerLocalStorage();
        return;
      }

      if (this.isComplete) {
        this.removeTimerLocalStorage();
        return;
      }

      setTimeout(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
          this.countDownTimer();
        } else {
          if (this.minutes > 0) {
            this.minutes -= 1;
            this.seconds = 59;
            this.countDownTimer();
          }
        }

        let timer = {
          minutes: this.minutes,
          seconds: this.seconds,
        };

        let storedTimer = this.openStorage();
        if (!storedTimer) {
          storedTimer = {};
        }

        storedTimer = timer;

        if (this.evaluationId) {
          this.saveStorage(storedTimer);
        }

        if (this.seconds == 0 && this.minutes == 0) {
          this.postResultExam();
          this.isComplete = true;
          this.dialog = true;
        }
      }, 1000);
    },
    openStorage() {
      return JSON.parse(localStorage.getItem("timer_" + this.evaluationId));
    },
    saveStorage(timer) {
      localStorage.setItem("timer_" + this.evaluationId, JSON.stringify(timer));
    },
    removeTimerLocalStorage() {
      localStorage.removeItem("timer_" + this.evaluationId);
    },
    setReview(questionPinned) {
      axios
        .post(this.$root.$options.api.url + "/questions/tag-question", {
          uuid: questionPinned.uuid,
          pinned: questionPinned.isPinned,
          course_id: this.course.id,
        });
    }
  },
};
</script>
