<template>
  <div class="tertiary fill-height">
    <v-container>
      <v-row class="py-0">
        <v-col cols="12">
          <page-banner>
            <template v-slot:subheader>Cours</template>
            <template
              v-slot:page-title
            >PERMIS LAC SUISSE (CAT. A ET D)</template>
          </page-banner>
          <course-nav :course-id="course.id"></course-nav>
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col cols="12" lg="10" offset-lg="1">
          <evaluation-list :evaluations="evaluations" :can_access="course.can_access"></evaluation-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import axios from "axios";
import { mapGetters } from "vuex";
import EvaluationList from "@/components/evaluation/List";
import PageBanner from "@/components/PageBanner";
import CourseNav from "@/components/course/Nav";

export default {
  components: {
    EvaluationList,
    PageBanner,
    CourseNav,
  },
  data() {
    return {
      evaluations: false,
      course: false,
    };
  },
  mounted() {
    this.getCourse();
    this.getEvaluations();
  },
  computed: {
    courseId() {
      return this.$route.params.courseId;
    },
    ...mapGetters({
      apiUrl: "base/apiUrl",
    }),
  },
  methods: {
    getCourse() {
      axios
        .get(this.$root.$options.api.url + "/courses/" + this.courseId)
        .then((response) => {
          this.course = response.data.course
        });
    },
    getEvaluations() {
      axios
        .get(this.$root.$options.api.url + "/evaluations")
        .then((response) => {
          this.evaluations = response.data.evaluations
        });
    },
  },
}

</script>
