<template>
  <div style="position: relative">
    <div
      v-if="content.addReview"
      @mouseover="expand = true"
      @mouseleave="expand = false"
      class="chapter-paragraph"
    >
      <v-tooltip v-if="pin" bottom color="secondary" openDelay="500">
        <template v-slot:activator="{ on, attrs }">
          <div class="d-inline-flex mb-2" v-bind="attrs" v-on="on">
            <v-checkbox
              :class="['mt-0', { 'pin-paragraph': !content.isPinned }]"
              hide-details
              v-model="content.isPinned"
              :value="content.active"
              off-icon="mdi-bookmark-plus-outline"
              on-icon="mdi-bookmark-check"
              @click="emitSetReview()"
            ></v-checkbox>
          </div>
        </template>
        <span v-if="!content.isPinned">{{ $t('study_notes.add') }}</span>
        <span v-if="content.isPinned">{{ $t('study_notes.remove') }}</span>
      </v-tooltip>
      <div class="chapter-paragraph">
        <div
          class="d-flex flex-wrap align-start justify-space-between"
          v-if="content.show_title"
        >
          <h2 class="text-h4 primary--text mb-4">{{ content.title }}</h2>
        </div>
        <div v-html="content.content" class="mb-4 mb-lg-8"></div>
        <template v-for="(image, index) in content.images">
          <v-sheet :key="index" class="mb-4 mb-lg-8">
            <v-img
              max-width="200"
              class="mx-auto"
              contain
              :src="image.url"
            ></v-img>
          </v-sheet>
        </template>
      </div>
    </div>
    <div v-if="pin && content.addReview" class="hover-border"></div>
    <div v-if="!content.addReview" class="chapter-paragraph">
      <div
        class="d-flex flex-wrap align-start justify-space-between"
        v-if="content.show_title"
      >
        <h2 class="text-h4 primary--text mb-4">{{ content.title }}</h2>
      </div>
      <div v-html="content.content"></div>
      <template v-for="(image, index) in content.images">
        <v-sheet :key="index" class="py-2">
          <v-img
            max-width="200"
            class="mx-auto"
            contain
            :src="image.url"
          ></v-img>
        </v-sheet>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pin: {
      default: true,
      type: Boolean,
    },
    content: {},
  },
  methods: {
    emitSetReview() {
      this.$emit("set-review", {
        uuid: this.$props.content.uuid,
        isPinned: this.$props.content.isPinned,
      });
    },
  },
};
</script>
