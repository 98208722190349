<template>
  <v-list-item>
    <v-checkbox v-model="answer.value" class="mr-2" :disabled="showResult">
      <template v-slot:label >
        <v-icon class="mr-1 mb-auto" small v-if="showResult" :style="answer.is_correct ? 'color:#4caf50 !important' : 'color:#ff5252 !important'">
          {{ answer.is_correct ? "mdi-checkbox-marked-circle" : "mdi-close-circle" }}
        </v-icon>
        <div
          class="grey--text text--darken-4 text-h6 mt-n1"
          :class="{
            'error--text': showResult && !answer.is_correct,
            'success--text': showResult && answer.is_correct,
          }"
        >{{ answer.answer }}
        </div>
      </template>
    </v-checkbox>
  </v-list-item>
</template>

<style lang="scss" scoped>

</style>

<script>
export default {
  props: ["answer", "showResult"],
  mounted() {},
};
</script>
