<template>
  <div>
    <template v-for="(evaluation, index) in evaluations">
      <v-hover :key="index" v-slot:default="{ hover }">
        <v-card
          link
          :ripple="false"
          :class="['mb-10', { 'on-hover': hover }]"
          :elevation="hover ? 5 : 2"
          :to="getEvaluationLink(evaluation.id)"
        >
          <v-row>
            <v-col cols="12" sm="8">
              <div class="px-4 py-4 fill-height">
                <h2 class="text-h5 primary--text mb-6">
                  {{ evaluation.evaluation }}
                </h2>
                <v-btn tile depressed small color="primary"
                >{{ $t('evaluations.start') }}
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="mx-2 mx-4 py-4 fill-height text-center best-score" v-if="evaluation.best_score">
                <h3 class="text-h6 primary--text mb-2">
                  {{ $t('evaluations.score_title') }}
                </h3>
                <div>
                  <p :class="[
                      'text-h3 mb-2',
                      {
                        'green--text': evaluation.best_score_scale === 'good',
                        'orange--text': evaluation.best_score_scale === 'medium',
                        'red--text': evaluation.best_score_scale === 'bad',
                      }
                  ]">{{ evaluation.best_score }}%</p>
                  <p class="small grey--text mb-0">({{ $t('evaluations.test_from') }} {{ evaluation.best_score_date }})</p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>
    </template>
  </div>
</template>

<script>
export default {
  props: [
    "evaluations",
    "courseId",
    "can_access",
  ],
  methods: {
    getEvaluationLink(evaluationId) {
      if (!this.$props.can_access) {
        return {
          name: 'free-evaluation',
          params: { evaluationId: evaluationId, courseId: this.$props.courseId },
        }
      }

      return {
        name: 'evaluation',
        params: { evaluationId: evaluationId, courseId: this.$props.courseId },
      }
    }
  }
};
</script>
