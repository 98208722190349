<template>
  <v-container class="py-0">
    <v-row class="py-0">
      <v-col cols="12">
        <page-banner>
          <template v-slot:subheader>{{ $t('modules.sub_header') }}</template>
          <template v-slot:page-title>{{ course.title }}</template>
        </page-banner>
        <v-tabs class="tabs-border-bottom" height="55">
          <v-tab link :to="{ name: 'course', params: { courseId: this.course.id }}">{{ $t('modules.modules') }}</v-tab>
          <v-tab link :to="{ name: 'evaluations', params: { courseId: this.course.id }}">{{ $t('modules.evaluations') }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <chapter-list :chapters="chapters"></chapter-list>
  </v-container>
</template>

<script>

import axios from 'axios'
import ChapterList from '@/components/chapter/List'
import PageBanner from '@/components/PageBanner'

export default {
  components: {
    ChapterList,
    PageBanner,
  },
  data() {
    return {
      module: false,
      chapters: false,
      course: false,
    }
  },
  computed: {
    moduleId() {
      return this.$route.params.moduleId
    },
  },
  mounted() {
    this.getModule()
  },
  methods: {
    getModule() {
      axios
      .get(this.$root.$options.api.url + '/modules/' + this.moduleId)
      .then(response => {
        this.module = response.data.module
        this.chapters = response.data.chapters
        this.course = response.data.course
      })
    }
  }
}

</script>
